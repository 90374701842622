
export const frStrings = {
    ponctuationColon: " :",
    color:"Couleur",
    stroke:"Contour",
    speed: "Débit",
    logOut: "Se déconnecter",
    accessDenied: "Accès refusé",
    firstConnection: "Première connexion",
    noPermissions: "Votre compte n'a pas les permissions requises ou est inconnu de l'application.",
    lastDocs: "Documents récents",
    displayDocsAllAgencies: "Afficher les documents de mes unités d'exploitation",
    fileExtension: "Extension",
    fileName: "Nom du fichier",
    agency: "Unité d'exploitation",
    territory: "Territoire",
    company: "Société juridique",
    productionSite: "Unité de production",
    physicalSite: "Site physique",
    contract: "Affaire",
    author: "Auteur",
    lastUpdate: "Dernière modification",
    delete: "Supprimer",
    showDetail: "Afficher",
    alert: "Alerte",
    yes: "Oui",
    no: "Non",
    absent: "Absent",
    present: "Présent",
    state: "Etat",
    loadingOk: "Chargement Ok",
    loadingKo: "Chargement Ko",
    ignoredDoc: "Document ignoré",
    dragAndDrop: "Déposer un document ou parcourir",
    btnEditAddressPerimeterEcontractJobSite: "Modifier l’adresse ou le contour d’une affaire/chantier",
    titleEditAddressPerimeterEcontractJobSite: "Modifier les adresses et contours des affaires/chantiers",
    save: "Enregistrer",
    cancel: "Annuler",
    patrimony: "Power BI",
    dropZone: "Dépôt",
    map: "Carte",
    formTitle: "Complétez les informations du projet",
    exit: "Quitter",
    ignoreSheet: "Ignorer ce fichier",
    createNewToggle: "Créer une nouvelle affaire.",
    agency_mnd: "Unité d'exploitation",
    structureType: "Type d'entité",
    label: "Libellé",
    id: "Id",
    address: "Adresse",
    email: "Email",
    phoneNumber: "Téléphone",
    webLink: "Lien web",
    labelContract_mnd: "Libellé affaire",
    labelCodeContract_mnd: "Code/Libellé affaire",
    TooltipInitialLocation: "Localisation initiale",
    labelCustomContract_mnd: "Libellé affaire (3 caractères minimum)",
    documentTitle_mnd: "Titre du document",
    searchBar: "Saisir 3 caractères pour commencer la recherche",
    searchBarCustomContract: "Saisir au moins 3 caractères",
    searchBarNoResult: "Aucun résultat, veuillez saisir au moins 3 caractères",
    searchBarNoResultHasMinLength: "Aucun résultat",
    contractCode: "Code affaire",
    contractClient: "Client affaire",
    contractState: "Statut affaire",
    documentInformation: "Compléter les informations du document",
    documentCreationTime: "Date de création du fichier",
    documentState: "Phase",
    projection: "Système de projection",
    category: "Catégorie de données",
    dataType: "Type de données",
    intellectualProperty: "Propriété intellectuelle",
    localization: "Localisation",
    locationSearchBar: "Rechercher une adresse",
    prevDoc: "Document précédent",
    nxtDoc: "Document suivant",
    loaded: "chargés",
    next: "Suivant",
    summTitle: "Récapitulatif des chargements",
    finalize: "Finaliser",
    noDoc: "Aucun document chargé",
    loadedDocs: "Documents chargés",
    deletionDialogTitle: "Supprimer un document",
    deletionDialogContent: "Confirmer la suppression du fichier",
    deletionDialogConfirmation: "Oui",
    deletionDialogCancelation: "Non, annuler",
    restrictionshowDetailMessage: "L'accès à ce document est restreint à l'unité d'exploitation sur laquelle il a été déposée. Pour plus d'information veuillez contacter :",
    preventShareToOtherStructures: "Je ne souhaite pas partager ce document avec d'autres unités d'exploitation",
    businessCheck: "Affaire",
    jobCheck: "Chantier",
    jobSiteIdentifier: "IdMdM",
    jobTooltip: "Permet d'associer un document à un chantier",
    contractTooltip: "Permet d'associer un document à une affaire",
    jobTitle: "Sélectionner le chantier",
    edit: "Modifier",
    confirmEdition: "Mettre à jour",
    rename:"Renommer",
    noRecords: "Aucune donnée disponible.",
    tags: "Mots clés",
    addTag: "Ajout de mots clés",
    keepTagForFutureFiles: "Conserver les mots clés pour les prochains fichiers",
    forgeViewer: "Viewer AutoDesk",
    fileViewer: "Visualisation de fichiers",
    uploadSearchLabel: "Rechercher..",
    uploadToggleButtonMyDocuments: "Mes documents",
    uploadToggleButtonMyAgencies: "Mes structures",
    uploadPopUpButtonBack: "Précédent",
    unavailableFileErrorMessage: "Fichier non disponible. Veuillez contacter votre administrateur.",
    fileDownloadAccessErrorMessage: "Ce document est privé, adressez vous à pierre.marechal@colas.com ou à benjamin.lecot@colas.com pour y avoir accès.",
    unavailableFicheErrorMessage: "Fiche non disponible. Veuillez contacter votre administrateur.",
    deleteFileSuccessMessage: "Le fichier a bien été supprimé. Vos modifications ont été enregistrées et seront visibles sur la carte dans 24h.",
    deleteFileErrorMessage: "Une erreur est survenue.",
    unvailableGpsCoordinatesForBusinessContractOrJobSiteErrorMessage: "Attention, adresse incorrecte, veuillez corriger l'adresse via le bouton \"Modifier l'adresse ou le contour d'une affaire/chantier\"",
    unvailableGpsCoordinatesForOtherStructureTypesErrorMessage: "Attention, adresse incorrecte",
    invalidFileSizeErrorMessage: "Au moins un des fichiers sélectionnés est vide ou pèse plus de 100Mo, merci de refaire une selection de fichier",
    invalidFileExtensionErrorMessage: "Au moins un des fichiers sélectionnés ne possède pas d’extension, merci de refaire une sélection de fichiers comprenant tous une extension.",
    requestUserRefreshPage: "Veuillez actualiser votre écran",
    popUpPolygonInformationMessage:"Les mesures affichées ci-dessous sont liées à la surface visible à l'écran.",
    latitude: "Latitude (deg)",
    longitude: "Longitude (deg)",
    opacity: "Opacité",
    minutes: "minutes",
    kilometers: "km",
    kilometersLabel: "Kilomètres",
    Meters: "Mètres",
    Feet: "Pieds",
    Yard: "Yards",
    Miles: "Miles",
    SqMeters:"Mètres carrés",
    SqKilometres:"Kilomètres carrés",
    SqFeet:"Sq. feet",
    Acres: "Acres",
    SqMiles:"Sq. miles",
    kilograms: "kg",
    saveChoice: "Sauvegarder mon choix",
    noData: "Aucune donnée",
    initialLocation: "Localisation initiale",
    newLocation: "Nouvelle localisation",
    preserveLayerDisplay: "Conserver l'affichage de la couche",
    layerName: "Nom de la couche",
    optionalLayerName: "Nom de la couche",
    myLayer: "Ma couche",
    structures: "Structures",
    height: "Hauteur",
    length: "Longueur",
    width: "Largeur",
    tons: "Tonnes",
    regions: "Régions",
    departments: "Départements",
    cantons: "Cantons",
    municipalities: "Communes",

    siren: "SIREN",
    name: "Nom",
    firstName: "Prénom",
    profil: "Profil",
    genericErrorMessage: "Une erreur est survenue",
    create: "Créer",
    language:"Langue",
    myProfile: "Mon profil",

    help: "Aide",
    tutorials: "Tutoriels",
    webSite: "Site web",
    guidedTour: "Visite guidée",

    poiCorrectionFormTitle: "Formulaire de correction",
    poiCorrectionFormSubtitle: "Vous avez repéré une anomalie, faites nous le savoir en remplissant ce formulaire.",
    poiCorrectionFormIdFieldLabel: "Identifiant",
    poiCorrectionFormDescriptionFieldLabel: "Description",
    poiCorrectionFormValidateButton: "Envoyer",
    poiCorrectionFormSuccessMessage: "Votre message a été envoyé.",
    poiCorrectionFormErrorMessage: "Une erreur est survenue.",
    poiEditAddressPerimeterEcontractButton: "Modifier l’adresse d’une affaire",
    poiEditAddressPerimeterJobButton: "Modifier l’adresse ou le contour d’un chantier",
    poiEditAddressTooltip: "Ajouter ou modifier l'adresse",
    poiEditPerimeterTooltip: "Ajouter ou modifier le contour",

    poiSearchTooltip: "Rechercher une adresse ou un point spécifique parmi ceux que vous affichez déjà",

    vectuelMapLoading: "Chargement...",
    vectuelMapLoadingError: "Une erreur est survenue.",
    vectuelMapSearchAddressGroup: "Adresses",
    vectuelMapSearchPoiGroup: "Points d'intérêt",
    vectuelMapItemsResult: "résultats",
    vectuelMapSearchLabel: "Rechercher",

    vectuelMapObjectType: "Objets",
    vectuelMapUeTitle: "Périmètre d'agence",
    vectuelMapLevel2Activities: "Activités",
    vectuelMapDateYear: "Année",
    vectuelMapDocumentDataCategory: "Fichier",
    vectuelMapDocumentExtension: "Format",
    vectuelMapEquipmentType: "Type de véhicule",
    vectuelMapErtTitle: "Territoire",
    vectuelMapCircularEconomyGrouping: "Label EC",
    vectuelMapSupplierCompany: "Société",
    vectuelMapSupplierSolvencyScore: "Score de solvabilité",
    vectuelMapClient: "Client",
    vectuelMapJobsiteStatus: "Statut du chantier",
    vectuelMapProductId: "ID Produits",
    vectuelMapProductLabel: "Libellé produits ",
    vectuelMapProductSpecialCode: "Produits spéciaux",
    vectuelMapEcontractStatus: "Statut des affaires",
    vectuelMapPhysicalSiteType: "Type de site",

    vectuelMapFilterTooltipUeTitle: "Filtre les points Chantier, Affaires et Matériel/équipement par Unité d'exploitation",
    vectuelMapFilterTooltipLevel2Activities: "Filtre les chantiers, unité d'exploitation, unité de production et fournisseurs Arctique par activité",
    vectuelMapFilterTooltipDateYear: "Filtre les chantiers et affaires par année de création",
    vectuelMapFilterTooltipDocumentDataCategory: "Filtre les chantiers, affaires et unité d'exploitation en fonction des categories de fichiers associées",
    vectuelMapFilterTooltipDocumentExtension: "Filtre les chantiers, affaires et unité d'exploitation en fonction des formats de fichiers associées",
    vectuelMapFilterTooltipEquipmentType: "Filtre les materiels/equipements en fonction du type de vehicule", 
    vectuelMapFilterTooltipErtTitle: "Filtre les unités d'exploitation, de production, chantiers, affaires et materiel par territoire",
    vectuelMapFilterTooltipCircularEconomyGrouping: "Filtre les unités de production en fonction de leur label economie circulaire",
    vectuelMapFilterTooltipSupplierCompany: "Filtre les fournisseurs par nom de société",
    vectuelMapFilterTooltipSupplierSolvencyScore: "Filtre les fournisseurs par score de solvabilté Infolegale",
    vectuelMapFilterTooltipClient: "Filtre les chantiers et affaires par client",
    vectuelMapFilterTooltipJobsiteStatus: "Filtre les chantiers par statut",
    vectuelMapFilterTooltipProductId: "Filtre les chantiers par ID produits mis en place",
    vectuelMapFilterTooltipProductLabel: "Filtre les chantiers par libellé produits mis en place",
    vectuelMapFilterTooltipProductSpecialCode: "Filtre les chantiers par produits spéciaux mis en place",
    vectuelMapFilterTooltipEcontractStatus: "Filtre les affaires par statut",
    vectuelMapFilterTooltipPhysicalSiteType: "Filtre les sites par type",

    vectuelMapFilterSubmitButton: "Valider",
    vectuelMapFiltersChooseToDisplay: "Sélectionner les filtres à afficher",
    vectuelMapFiltersDisplaySelected: "Afficher les filtres sélectionnés",
    vectuelMapPOIInfosTab: "Informations",
    vectuelMapPOIContactsTab: "Contacts",
    vectuelMapPOIFilesTab: "Fichiers",
    vectuelMapPOIFieldId: "Identifiant",
    vectuelMapPOIFieldLabel: "Nom",
    vectuelMapPOIFieldShape: "Surface (km²)",
    vectuelMapPOIFieldLength: "Longueur (km)",
    vectuelMapPOIFieldTotalPop: "population totale",
    vectuelMapPOIFieldStatus: "Statut",
    vectuelMapPOIFieldPop94: "Population 1994",
    vectuelMapPOIFieldPop04: "Population 2004 ",
    vectuelMapPOIFieldHouseholdNumber: "Nombre de menage",
    vectuelMapPOIFieldOsmId: "OSM Id",
    vectuelMapPOIFielCode: "Code",
    vectuelMapPOIFielClass: "Classe",
    vectuelMapPOIFieloneWay: "Sens unique",
    vectuelMapPOIFielMaxSpeed: "Vitesse maximale",
    vectuelMapPOIFielBridge: "Pont",
    vectuelMapPOIFielTunnel: "Tunnel",
    vectuelMapPOIFieldContract: "Nom de l'affaire",
    vectuelMapPOIFieldErt: "Territoire",
    vectuelMapPOIFieldUE: "Unité d'exploitation",
    vectuelMapPOIFieldSJ: "Société juridique",
    vectuelMapPOIFieldAddress: "Adresse",
    vectuelMapPOIFieldActivities: "Activités",
    vectuelMapPOIFieldInfracareLink: "Lien URL ver infraCARE",
    vectuelMapPOIFieldClient: "Client",
    vectuelMapPOIFieldJobsiteAmount: "Montant",
    vectuelMapPOIFieldMainContractManager: "Conducteur de travaux principal",
    vectuelMapPOIFieldMainForeman: "Chef de chantier principal",
    vectuelMapPOIFieldPlannedStartDate: "Date de démarrage",
    vectuelMapPOIFieldJobsiteStatus: "Statut du chantier",
    vectuelMapPOIFieldJobsiteProducts: "Produits",
    vectuelMapPOIFieldJobsiteShowProductsDetails: "Voir les produits",
    vectuelMapPOIFieldJobsiteLocateProduct: "Localiser les produits",
    vectuelMapPOIFieldJobsiteLocateProductErrorMessage: "Aucun tracé de localisation de produit n'est disponible pour ce chantier",
    vectuelMapPOIFieldJobsiteProductId: "Id Produit",
    vectuelMapPOIFieldJobsiteProductLabel: "Libellé",
    vectuelMapPOIFieldJobsiteProductSegmentation: "Segmentation",
    vectuelMapPOIFieldJobsiteProductStartDate: "Date début",
    vectuelMapPOIFieldJobsiteProductEndDate: "Date fin",
    vectuelMapPOIFieldJobsiteProductQuantity: "Quantité",
    vectuelMapPOIFieldJobsiteProductUnits: "Unité",
    vectuelMapPOIFieldJobsiteERT: "Territoire/ERT",
    vectuelMapPOIFieldJobsiteUP: "Industrie",
    vectuelMapPOIFieldJobsiteProductRock: "Roche",
    vectuelMapPOIFieldJobsiteProductBitumen: "Bitume",
    vectuelMapPOIFieldJobsiteProductColor: "Couleur",
    vectuelMapPOIFieldJobsiteProductGranulometry: "Granulométrie",
    vectuelMapPOIFieldJobsiteProductAsphaltFamily: "Famille enrobé",
    vectuelMapPOIFieldJobsiteProductClass: "Classe",
    vectuelMapPOIFieldJobsiteProductColasSpecial: "Produit spécial Colas",
    vectuelMapPOIFieldJobsiteProductTemperature: "Température",
    vectuelMapPOIFieldJobsiteProductTemperatureRange: "Gamme T°",
    vectuelMapPOIFieldJobsiteProductProductAsphaltAggregateRatio: "Taux AE",
    vectuelMapPOIFieldJobsiteProductOptimized: "Optimisé ou pas",
    vectuelMapPOIFieldEcontractStatus: "Statut",
    vectuelMapPOIFieldUESites: "Sites",
    vectuelMapPOIFieldJobsites: "Liste des chantiers",
    vectuelMapPOIFieldDocuments: "Documents associés",
    vectuelMapPOIFieldEquipmentType: "Type de véhicule",
    vectuelMapPOIFieldEquipmentBrand: "Marque",
    vectuelMapPOIFieldEquipmentModel: "Modèle",
    vectuelMapPOIFieldEquipmentLicensePlate: "Immatriculation",
    vectuelMapPOIFieldGpsCoordinates: "Coord. GPS",
    vectuelMapPOIFieldEquipmentStatus: "Etat",
    vectuelMapPOIFieldLastMaintenanceDate: "Dernière maintenance",
    vectuelMapPOIFieldNextMaintenanceDate: "Prochaine maintenance",
    vectuelMapPOIFieldSupplierSite: "Site",
    vectuelMapPOIFieldCompany: "Société",
    vectuelMapPOIFieldSupplierActivities: "Activités",
    vectuelMapPOIFieldSiret: "N° SIRET",
    vectuelMapPOIFieldSupplierSolvencyScore: "Score de solvabilité (sur 20)",
    vectuelMapPOIFieldSupplierContacts: "Contacts",
    vectuelMapPOIFieldJobsiteId: "idMdm chantier associé",
    vectuelMapPOIFieldComments: "Commentaires",
    vectuelMapPOIFieldAuscultationLastStatementDate: "Date de dernière relevé",
    vectuelMapPOIFieldAusculationTotalLength: "Nombre de km total",
    vectuelMapPOIFieldAusculationBadLength: "% qualité mauvaise",
    vectuelMapPOIFieldAusculationTomonitorLength: "% qualité moyenne",
    vectuelMapPOIFieldAusculationGoodLength: "% qualité bonne",
    vectuelMapPOIOpenCorrectionFormLabel: "Modifier les informations",
    vectuelMapPOIOpenCorrectionFormToolTipNoUe: "Impossible de modifier l'adresse ou le contour d'une affaire/chantier qui n'est pas rattaché à une UE",
    vectuelMapPOIOpenCorrectionFormToolTipNoRight: "Vous n'avez pas les droits pour modifier l'adresse ou le contour de cette affaire/chantier",
    vectuelMapPOIChantier360Label: "Chantier 360",
    vectuelMapPOICarbonCounterLabel: "CCC",
    vectuelMapSelectAllFilters: "Tout sélectionner",
    vectuelMapOpenDataNationalLabel: "Open Data Nationale",
    vectuelMapOpenDataDepartmentalLabel: "Open Data Départementale",
    vectuelMapCantonaleLabel: "Open Data Cantonale",
    vectuelMapColasDataLabel: "Données Colas",
    vectuelMapPOIFieldCompetitorArea: "Bassin",
    vectuelMapPOIFieldInsee: "Code INSEE",
    vectuelMapPOIEmptyAdress: "Préciser l'adresse en cliquant en bas de page",
    vectuelMapPOIEmptyTooltip: "Il s'agit par défaut de l'adresse de l'UE",
    vectuelMapPOIFieldPlanonLink: "OCRE PLANON",
    
    vectuelMapMyLayers: "Mes couches",
    vectuelMapNoLayersSelected: "Aucune couche n'est selectionnée",
    vectuelMapNoMarkersSelected: "Aucun point n'est selectionné",

    vectuelMapPOIPropetiesEquipmentCode:"Code équipement",
    vectuelMapPOIPropetiesEquipmentBrand:"Marque de l'équipement",
    vectuelMapPOIPropetiesEquipmentModel:"Modèle de l'équipement",
    vectuelMapPOIPropetiesQuantity:"Quantité",
    vectuelMapPOIPropetiesEquipmentCodeWorkTime:"Temps travaillé",
    vectuelMapPOIPropetiesDistance: "Distance",

    vectuelMapPOILocationTypeLabel: "Type de site",

    vectuelMapPOIProposalI: "Ordre de traitement",
    vectuelMapPOIProposalT: "Proposal t",
    vectuelMapPOIUrsn: "USRN",
    vectuelMapPOISectionRe: "Section re",
    vectuelMapPOIRoadname: "Nom de la route",
    vectuelMapPOIRoadNumber: "Numéro de la route",
    vectuelMapPOIRoadClass: "Classe de la route",
    vectuelMapPOICostEstimationInPounds: "Estimation du prix (£)",
    vectuelMapPOILengthMeter: "Longueur (m)",
    vectuelMapPOISurfaceSquareMeter: "Surface (m²)",
    vectuelMapPOIDamageAreaSquareMeter: "Surface endommagée (m²)",
    vectuelMapPOIGrade1: "Niveau 1 (m²)",
    vectuelMapPOIGrade2: "Niveau 2 (m²)",
    vectuelMapPOIGrade3: "Niveau 3 (m²)",
    vectuelMapPOIGrade4: "Niveau 4 (m²)",
    vectuelMapPOIGrade5: "Niveau 5 (m²)",
    vectuelMapPOIMajorJCT: "Major jct",
    vectuelMapPOIW3w: "w3w",
    vectuelMapPOIGid: "GID",
    vectuelMapPOISurveyDate: "Date de relevé",
    vectuelMapPOIGrade: "Niveau",
    vectuelMapPOIGradeDesc: "Niveau desc",
    vectuelMapPOIDamages: "Dégradation",
    vectuelMapPOIDamagesWidthMeter: "Largeur des dégradations (m)",
    vectuelMapPOIDamagesAreaSquareMeter: "Surface endommagée (m²)",
    vectuelMapPOISurface: "Matériau",
    vectuelMapPOIWitdhMeter: "Largeur (m)",
    vectuelMapPOIUts: "UTS",
    vectuelMapPOILinkImage: "Lien de l'image",
    vectuelMapPOIResurveyed: "Date de relevé",
    vectuelMapPOIWard: "Sucursale",
    vectuelMapPOIRoadType: "Type de route",
    vectuelMapPOIJunction: "Jonction",
    vectuelMapPOIMajorJunction: "Jonction majeure",
    vectuelMapPOIMain: "Principal",
    vectuelMapPOIRoadOver: "Route supperposée",
    vectuelMapPOIOsName: "Nom OS",
    vectuelMapPOICorridor: "Couloir",
    vectuelMapPOIWpa: "WPA",
    vectuelMapPOIRadius: "Rayon",
    vectuelMapPOIAngle: "Angle",
    vectuelMapPOIAltitude: "Altitude",
    vectuelMapPOIGradient: "Grandient",
    vectuelMapPOIAdjacency: "Proximité",

    vectuelMapOpenDataLayerCategoryAdministrative: "Administratif",
    vectuelMapOpenDataLayerCategoryTownPlanning: "Urbanisme",
    vectuelMapOpenDataLayerCategoryEnvironment: "Environnement",
    vectuelMapOpenDataLayerCategoryEnergyInfrastructure: "Infrastructure énergétique",
    vectuelMapOpenDataLayerCategoryRoadInfrastructure: "Infrastructures routières",
    vectuelMapOpenDataLayerCategoryUrbanPlanning: "Urbanisme",
    vectuelMapCwacLayerCategory: "CWAC Data",
    vectuelMapDepartmental92LayerCategory: "92 - Hauts de Seine",
    vectuelMapOpenDataLayerCategoryTransportInfrastructure: "Infrastructure de transport",
    vectuelMapMyLayersCategoryItinerary: "Couches itinéraire",
    vectuelMapMyLayersCategoryCreatedLayers: "Couches créées",
    vectuelMapMyImportedLayersCategory: "Couches importées",
    vectuelOpenData: "Open Data",

    vectuelStreetViewLabel: "Street View",
    vectuelMapLayersLabel: "Couches",
    vectuelMapDisplayLabel: "Affichage",
    vectuelMapLegendLabel: "Légende",
    vectuelMapToolsLabel: "Outils",
    vectuelMapPlotsLabel: "Parcelles",
    vectuelMapMarkersLabel: "Points",
    vectuelMapCalculationLabel: "Calculer",
    vectuelMapDrawingLabel: "Dessin",
    vectuelMapImportLabel: "Import",
    vectuelMapOthersLabel: "Autres",
    vectuelMapDrawingClearLabel: "Nettoyer",
    vectuelMapDrawingLabelFeaturesList: "Features",
    vectuelMapDrawingLabelFeature: "Feature",
    
    vectuelMapToolDrawingPoint: "Point",
    vectuelMapToolDrawingMultiPoint: "MultiPoint",
    vectuelMapToolDrawingLine: "Ligne",
    vectuelMapToolDrawingMultiLine: "MultiLigne",
    vectuelMapToolDrawingPolygon: "Surface",
    vectuelMapToolDrawingMultiPolygon: "MultiSurface",
    vectuelMapToolDrawingText: "Texte",
    vectuelMapToolDrawingDrawPoint: "Dessiner un point",
    vectuelMapToolDrawingDrawLine: "Dessiner une ligne",
    vectuelMapToolDrawingDrawPolygon: "Dessiner une surface",
    vectuelMapToolDrawingDrawText: "Placer un texte",
    vectuelMapToolDrawingEnterName: "Saisir un nom",
    vectuelMapToolDrawingCreate: "Créer",
    vectuelMapToolDrawingExport: "Exporter",
    vectuelMapToolDrawingSave: "Sauvegarder",
    vectuelMapToolDrawingSaveNew: "Sauvegarder nouveau",
    vectuelMapToolDrawingSaveNewTooltip:"Sauvegarder comme une nouvelle couche.",
    vectuelMapToolDrawingPropertieName: "Nom de la propriété",
    vectuelMapToolDrawingPropertieValue: "Valeurs",

    vectuelMapLayerCatchment: "Chalandise carrière",
    vectuelMapLayerJobContour: "Contours de chantiers",
    vectuelMapLayerRoadSurvey: "Relevé de route",
    vectuelMapLayerColasCatchmentUe: "Sectorisation des agences",
    vectuelMapLayerColasPKroute:"Points kilométriques",

    vectuelMapLayerClaySwellingRisk: "FR - Risque de gonflement des argiles",
    vectuelMapLayerLandGeologicalRisks: "FR - Risques géologiques",
    vectuelMapLayerLandGeology: "FR - Couches géologiques",
    vectuelMapLayerLandNaturalRisks: "FR - Risques naturels",
    vectuelMapLayerLandRegister: "FR - Cadastre",
    vectuelMapLayerLandRegisterPrecision: "FR - Précision du cadastre",
    vectuelMapLayerLandSoilPermeability: "FR - Imperméabilisation des sols",
    vectuelMapLayerPostesHtaBt: "FR - Enedis - Postes sources HTA/BT",
    vectuelMapLayerPostesSourcesHta: "FR - Enedis - Postes sources HTA/HTA",
    vectuelMapLayerPoteauxHtaBt: "FR - Enedis - Poteaux HTA ou BT",
    vectuelMapLayerReseauABt: "FR - Enedis - Lignes aériennes BT",
    vectuelMapLayerReseauAHt: "FR - Enedis - Lignes aériennes HTA",
    vectuelMapLayerReseauBt: "FR - Enedis - Lignes souterraines BT",
    vectuelMapLayerReseauHta: "FR - Enedis - Lignes souterraines HTA",
    vectuelMapLayerGazNetwork: "FR - GRDF - Réseaux de gaz",
    vectuelMapLayerTrees: "FR - Arbre",
    vectuelMapLayerBusStops: "FR - Arrêt de bus",
    vectuelMapLayerPedestrianCrossings: "FR - Passage piéton",
    vectuelMapLayerTrafficSignals: "FR - Feu de circulation",
    vectuelMapLayerHospitals: "FR - Hôpital",
    vectuelMapLayerRetirementHomes: "FR - Maison de retraite",
    vectuelMapLayerParkings: "FR - Parking",
    vectuelMapLayerWastelands: "FR - Friche",
    vectuelMapLayerWasteDisposals: "FR - Déchèterie et point de collecte",
    vectuelMapLayerStores: "FR - Commerce",
    vectuelMapLayerBikeParkings: "FR - Parking vélo",
    vectuelMapLayerBikePaths: "FR - Piste cyclable",
    vectuelMapLayerSchools: "FR - Ecole",
    vectuelMapLayerPublicServices: "FR - Service public",
    vectuelMapLayerChargingStations: "FR - Borne de recharge",
    vectuelMapLayerBuildingPermits: "FR - Permis de construire",
    vectuelMapLayerNoiseExposures: "FR - Exposition aux bruits",
    vectuelMapLayerPlu: "FR - PLU",
    vectuelMapLayerAccidents: "FR - Accident",
    vectuelMapLayerChargingPointsIrve: "Bornes de recharge électrique",
    vectuelMapLayerParkingsSup500: "Parkings (Minimum 500m²)",
    vectuelMapLayerParkingZone: "Zones de stationnement",

    vectuelMapLayerRegions: "MA - Région",
    vectuelMapLayerProvinces: "MA - Province",
    vectuelMapLayerMunicipalities: "MA - Commune",
    vectuelMapLayerCities: "MA - Ville",
    vectuelMapLayerRails: "MA - Rail",
    vectuelMapLayerRoads: "MA - Route",

    vectuelMapLayerMapStyleOsm2D: "OSM 2D",
    vectuelMapLayerMapStyleSat2D: "SAT 2D",
    vectuelMapLayerMapStyleOsm3D: "OSM 3D",
    vectuelMapLayerMapStyleSat3D: "SAT 3D",
    vectuelMapLayerMapStyleGlobe3D: "GLOBE 3D",

    vectuelMapLayerCarriageway2022: "AN - Chaussées 2022",
    vectuelMapLayerPatching2022: "AN - Interventions 2022",
    vectuelMapLayerCW2022: "AN - Auscultation 2022",
    vectuelMapLayerCW2021: "AN -Auscultation 2021",

    vectuelMapLayerRoadSurveyTooltip: "Infracare est une plateforme qui permet une gestion pluriannuelle plus performante avec pour objectif de surveiller, d’entretenir et d’améliorer l’état des infrastructures, en favorisant la logique d’entretien préventif. - Via infracare.colas.com ",
    vectuelMapLayerLandRegisterTooltip: "Une information cadastrale numérique, géoréférencée et continue, adaptée au droit des sols, à l'urbanisme et à la gestion des réseaux. - Via geoservices.ign.fr",
    vectuelMapLayerLandRegisterPrecisionTooltip: "Le degré de précision et de mise à jour des données cadastrales - Via geoservices.ign.fr",
    vectuelMapLayerLandNaturalRisksTooltip: "Communes concernées par un plan de prévention des risques naturels - Via georisques.gouv.fr",
    vectuelMapLayerLandGeologicalRisksTooltip: "Cavités souterraines abandonnées d'origine non minière - Via geoservices.brgm.fr",
    vectuelMapLayerLandGeologyTooltip: "Carte géologique 1/1 000 000 de la France Métropolitaine - Via infoterre.brgm.fr",
    vectuelMapLayerLandSoilPermeabilityTooltip: "Taux d'imperméabilisation des sols en France métropolitaine et outre-mer - Via geoservices.ign.fr",
    vectuelMapLayerClaySwellingRiskTooltip: "Zones d'exposition au retrait-gonflement des argiles - Via geoservices.ign.fr ",
    vectuelMapLayerPostesHtaBtTooltip: "Position des postes source et postes de répartition HTA/BT sur l’ensemble du réseau de distribution exploité par Enedis - Via data.enedis.fr",
    vectuelMapLayerPostesSourcesHtaTooltip: "Position des postes source et postes de répartition HTA/HTA sur l’ensemble du réseau de distribution exploité par Enedis - Via data.enedis.fr",
    vectuelMapLayerPoteauxHtaBtTooltip: "Position des poteaux HTA, BT et des remontés aéro-souterraine sur l’ensemble du réseau de distribution exploité par Enedis. ",
    vectuelMapLayerReseauABtTooltip: "Position des lignes aériennes Basse Tension (BT) sur l’ensemble du réseau de distribution exploité par Enedis - Via data.enedis.fr",
    vectuelMapLayerReseauAHtTooltip: "Position des lignes aériennes Moyenne Tension (HTA) sur l’ensemble du réseau de distribution exploité par Enedis - Via data.enedis.fr",
    vectuelMapLayerReseauBtTooltip: "Position des lignes souterraines Basse Tension (BT) sur l’ensemble du réseau de distribution exploité par Enedis - Via data.enedis.fr",
    vectuelMapLayerReseauHtaTooltip: "Position des lignes souterraines Moyenne Tension (HTA) sur l’ensemble du réseau de distribution exploité par Enedis - Via data.enedis.fr ",
    vectuelMapLayerGazNetworkTooltip: "Ensemble des canalisations en service du réseau de distribution de gaz exploité par GRDF - Via opendata.grdf.fr",
    vectuelMapLayerChargingPointsIrveTooltip: "Données relatives à la localisation géographique et aux caractéristiques techniques des stations et des points de recharge de véhicules électriques ouverts au public - Via data.gouv.fr",
    vectuelMapLayerParkingsSup500Tooltip: "Parkings de plus de 500m² - Via geoservice.ign.fr 2023",
    vectuelMapLayerParkingZoneTooltip: "Unités foncières contenant des surfaces de stationnement non couvertes de plus de 500m² (données déclaratives) - Via geoservice.ign.fr 2022",

    vectuelMapLayerPopUpCategory: "Catégorie",
    vectuelMapLayerPopUpNature: "Nature",
    vectuelMapLayerPopUpName: "Nom",
    vectuelMapLayerPopUpPinName: "Nom du point",
    vectuelMapLayerPopUpLineName: "Nom de la ligne",
    vectuelMapLayerPopUpStatus: "Etat",
    vectuelMapLayerPopUpCreationDate: "Date création",
    vectuelMapLayerPopUpUpdateDate: "Date MAJ",
    vectuelMapLayerPopUpCity: "Commune",
    vectuelMapLayerPopUpAddress: "Adresse",
    vectuelMapLayerPopUpPollution: "Pollution",
    vectuelMapLayerPopUpType: "Type",
    vectuelMapLayerPopUpTypeFr: "Type FR",
    vectuelMapLayerPopUpOperator: "Opérateur",
    vectuelMapLayerPopUpWebsite: "Site web",
    vectuelMapLayerPopUpPhone: "Téléphone",
    vectuelMapLayerPopUpCapacity: "Capacité",
    vectuelMapLayerPopUpAccess: "Accès",
    vectuelMapLayerPopUpOpeningHour: "Horaires d'ouverture",
    vectuelMapLayerPopUpEmail: "Email",
    vectuelMapLayerPopUpPermitId: "ID permis",
    vectuelMapLayerPopUpLotSize: "Superficie du terrain",
    vectuelMapLayerPopUpDAUState: "Etat DAU",
    vectuelMapLayerPopUpDAUType: "Type DAU",
    vectuelMapLayerPopUpBuilderName: "Nom aménageur",
    vectuelMapLayerPopUpOperatorName: "Nom opérateur",
    vectuelMapLayerPopUpNbChargingPoint: "Nombre borne de charge",
    vectuelMapLayerPopUpZone: "Zone",
    vectuelMapLayerPopUpStoppingDate: "Date de l'arrêt",
    vectuelMapLayerPopUpDocRef: "Lien",
    vectuelMapLayerPopUpId: "Id",
    vectuelMapLayerPopUpLabel: "Libellé",
    vectuelMapLayerPopUpZoneType: "Type zone",
    vectuelMapLayerPopUpKill: "Tué(s)",
    vectuelMapLayerPopUpInjured: "Blessé(s)",
    vectuelMapLayerPopUpHospitalized: "Dont Hospitalisé(s)",
    vectuelMapLayerPopUpLightInjured: "Dont léger(s)",
    vectuelMapLayerPopUpDate: "Date",
    vectuelMapLayerPopUpDepartmentName: "Nom département",
    vectuelMapLayerPopUpRegionName: "Nom région",
    vectuelMapLayerPopUpNaturePostesHtaBt: "Poste de distribution publique (poste HTA/BT)",
    vectuelMapLayerPopUpNaturePostesSourcesHta: "Poste source et Poste de Répartition HTA/HTA",
    vectuelMapLayerPopUpNaturePoteauxHtaBt: "Poteau HTA ou BT",
    vectuelMapLayerPopUpNatureReseauABt: "Ligne aérienne Basse Tension (BT)",
    vectuelMapLayerPopUpNatureReseauAHt: "Ligne aérienne moyenne tension (HTA)",
    vectuelMapLayerPopUpNatureReseauBt: "​Ligne souterraine Basse Tension (BT)",
    vectuelMapLayerPopUpNatureReseauHta: "Ligne souterraine moyenne tension (HTA)",
    vectuelMapLayerPopUpTitlePostesHtaBt: "Poste HTA/BT",
    vectuelMapLayerPopUpTitlePostesSourcesHta: "Poste source et Poste de Répartition HTA/HTA",
    vectuelMapLayerPopUpTitlePoteauxHtaBt: "Poteau HTA ou BT",
    vectuelMapLayerPopUpTitleReseauABt: "Ligne aérienne BT",
    vectuelMapLayerPopUpTitleReseauAHt: "Ligne aérienne HTA",
    vectuelMapLayerPopUpTitleReseauBt: "Ligne souterraine BT",
    vectuelMapLayerPopUpTitleReseauHta: "Ligne souterraine HTA",
    vectuelMapLayerPopUpTabTitleProperties: "Propriétés",
    vectuelMapLayerPopUpTabTitleInformations: "Informations",
    vectuelMapLayerPopUpTabTitleStyle: "Style",
    vectuelMapLayerPopUpSizeField: "Taille de l'icone",
    vectuelMapLayerPopUpWeightField: "Largeur",
    vectuelMapLayerPopUpSizeFieldSmall: "Petit",
    vectuelMapLayerPopUpSizeFieldNormal: "Normal",
    vectuelMapLayerPopUpSizeFieldBig: "Grand",
    vectuelMapLayerPopUpIconField: "Icone",
    vectuelMapLayerPopUpDeleteButton: "Supprimer la forme",
    vectuelMapLayerPopUpFontSizeField: "Taille du texte",
    vectuelMapLayerPopUpBorderColor: "Contour texte",
    vectuelMapLayerPopUpStroke:"Contour",
    vectuelMapLayerPopUpAddproperty: "Ajouter une propriété",


    vectuelMapObjectTypeFilterCategoryBoreholes: "Sondages",
    vectuelMapObjectTypeFilterCategoryColas: "Colas",
    vectuelMapObjectTypeFilterCategoryRefineries: "Raffineries",
    vectuelMapObjectTypeFilterCategorySuppliers: "Fournisseurs",

    vectuelMapObjectTypeFilterPluralArctiqueData: "Fournisseurs concurrents",
    vectuelMapObjectTypeFilterPluralBrgmBoreholes: "Sondages BRGM",
    vectuelMapObjectTypeFilterPluralLegalCompany: "Sociétés juridiques",
    vectuelMapObjectTypeFilterPluralExploitationUnit: "Unités d'exploitation",
    vectuelMapObjectTypeFilterPluralJob: "Chantiers",
    vectuelMapObjectTypeFilterPluralMaterialEquipment: "Matériel/Equipements",
    vectuelMapObjectTypeFilterPluralModel3d: "Maquettes 3D",
    vectuelMapObjectTypeFilterPluralProductionUnit: "Unités de production",
    vectuelMapObjectTypeFilterPluralProject: "Affaires",
    vectuelMapObjectTypeFilterPluralRefinery: "Raffineries",
    vectuelMapObjectTypeFilterPluralRoadStatement: "Relevé de routes",
    vectuelMapObjectTypeFilterPluralSupplier: "Fournisseurs divers",
    vectuelMapObjectTypeFilterPluralPhysicalSite: "Sites physiques",

    vectuelMapObjectTypeFilterPluralArctiqueDataTooltip: "Fournisseurs COLAS et les activités associées - Source: Arctique",
    vectuelMapObjectTypeFilterPluralBrgmBoreholesTooltip: "Données de la Banque du sous-sol et liens vers les fiches InfoTerre - Source: infoterre.brgm.fr",
    vectuelMapObjectTypeFilterPluralLegalCompanyTooltip: "Sociétés juridiques COLAS - Source: Hubble",
    vectuelMapObjectTypeFilterPluralExploitationUnitTooltip: "Unités d'exploitation COLAS - Source: Hubble",
    vectuelMapObjectTypeFilterPluralJobTooltip: "Chantiers en cours ou terminés - Source: Hubble",
    vectuelMapObjectTypeFilterPluralMaterialEquipmentTooltip: "Matériel et équipements - Source: MyEquipment",
    vectuelMapObjectTypeFilterPluralModel3dTooltip: "Modèles numériques ajoutés sur la carte",
    vectuelMapObjectTypeFilterPluralProductionUnitTooltip: "Unités de production COLAS - Usines et carrières - Source: Hubble",
    vectuelMapObjectTypeFilterPluralProjectTooltip: "Affaires - Source: HUBBLE",
    vectuelMapObjectTypeFilterPluralRefineryTooltip: "Raffineries COLAS - Source: HUBBLE",
    vectuelMapObjectTypeFilterPluralRoadStatementTooltip: "Infracare est une plateforme qui permet une gestion pluriannuelle plus performante avec pour objectif de surveiller, d’entretenir et d’améliorer l’état des infrastructures, en favorisant la logique d’entretien préventif. Source: infracare.colas.com ",
    vectuelMapObjectTypeFilterPluralSupplierTooltip: "Fournisseurs COLAS et les activités associées - Source: Hubble",
    vectuelMapObjectTypeFilterPluralPhysicalSiteTooltip: "Sites physiques - Source : HUBBLE",

    vectuelMapPOIFieldBrgmIndex: "Indice ",
    vectuelMapPOIFieldBrgmAbbreviation: "Designation",
    vectuelMapPOIFieldBrgmInfoTerreLink: "Lien fiche INFOTERRE",
    vectuelMapPOIFieldBrgmInfoTerreLinkDeleted: "Ce sondage n'est plus disponible sur Infoterre.",
    vectuelMapPOIFieldBrgmHasGeoCrossSection: "Coupe Géologique",
    vectuelMapPOIFieldBrgmHasOtherGeoCrossSection: "Autre Coupe",
    vectuelMapPOIFieldBrgmVerifiedGeoLog: "Log Géologique Vérifié",
    vectuelMapPOIFieldBrgmWaterPoint: "Point EAU",
    vectuelMapPOIFieldBrgmXEntered: "X Saisie",
    vectuelMapPOIFieldBrgmYEntered: "Y Saisie",
    vectuelMapPOIFieldBrgmProjectionEntered: "Système de projection saisie",
    vectuelMapPOIFieldBrgmCoordinateUnitEntered: "Unité des coordonnées saisies",
    vectuelMapPOIFieldBrgmZSoil: "Altitude Z",
    vectuelMapPOIFieldBrgmZSoilPrecision: "Lex Prec Z sol",
    vectuelMapPOIFieldBrgmZAltitude: "Z BDalti",
    vectuelMapPOIFieldBrgmType: "Nature du point",
    vectuelMapPOIFieldBrgmDepthProbe: "Profondeur d'investigation",
    vectuelMapPOIFieldBrgmTubeDiameter: "Diamètre tubage",
    vectuelMapPOIFieldBrgmGroundWaterDepth: "Niveau d'eau dans le sol",
    vectuelMapPOIFieldBrgmSoilWaterMeasureDate: "Date de la mesure du niveau d'eau",
    vectuelMapPOIFieldBrgmZOriginCrossSection: "Z d'origine de la coupe",
    vectuelMapPOIFieldBrgmCrossSectionDate: "Date la coupe",
    vectuelMapPOIFieldBrgmEndWorkDate: "Date fin de travaux",
    vectuelMapPOIFieldBrgmGeoMapNumber: "Numéro carte géologique",
    vectuelMapPOIFieldBrgmGeoMapName: "Nom carte géologique",
    vectuelMapPOIFieldBrgmPointCondition: "Etat du point",
    vectuelMapPOIFieldBrgmExecutionMaterial: "Moyen d'execution",
    vectuelMapPOIFieldBrgmExploitationType: "Type d'exploitation",
    vectuelMapPOIFieldBrgmRecognitionType: "Type de reconnaissance",
    vectuelMapPOIFieldBrgmDocuments: "Document associés",
    vectuelMapPOIFieldBrgmDocumentsReference: "Référence documents",
    vectuelMapPOIFieldBrgmFiledDate: "Date du dossier",
    vectuelMapPOIFieldBrgmFillDate: "Date de saisie",
    vectuelMapPOIFieldBrgmLastUpdateDate: "Date de la dernière MaJ",

    vectuelMapPOIFieldRefineryTel: "Telephone",
    vectuelMapPOIFieldRefineryFax: "Fax",
    vectuelMapPOIFieldRefineryEmail: "Email",
    vectuelMapPOIFieldRefineryCapacity: "Capacité totale (10³T/an)",
    vectuelMapPOIFieldRefineryVaccumDistillation: "Distillation sous vide (10³T/an)",
    vectuelMapPOIFieldRefineryOperator: "Opérateur",
    vectuelMapPOIFieldRefineryBlowing: "Soufflage (10³T/an)",
    vectuelMapPOIFieldRefineryCatalyticCracking: "Craquage catalytique (T/j)",
    vectuelMapPOIFieldRefineryVisbreaking: "Viscoreduction (T/j)",
    vectuelMapPOIFieldRefineryDeasphalting: "Désaphaltage",
    vectuelMapPOIFieldRefineryOtherUnits: "Autres unités",
    vectuelMapPOIFieldRefineryProdCapacity: "Capacité de production (T/an)",
    vectuelMapPOIFieldRefineryShortResidue: "Résidu court ",
    vectuelMapPOIFieldRefineryHeavyDistillate: "Distillat lourd",
    vectuelMapPOIFieldRefineryAromaticExtract: "Extrait aromatique ",
    vectuelMapPOIFieldRefineryVisbrokenResidue: "Résidu viscoréduit",
    vectuelMapPOIFieldRefineryOtherRawMaterials: "Autres matières premières",
    vectuelMapPOIFieldRefineryOnLineBlending: "Mélange en ligne",
    vectuelMapPOIFieldRefineryAdditive: "Additif",
    vectuelMapPOIFieldRefineryOtherProcess: "Autre process",
    vectuelMapPOIFieldRefineryBitumenType: "Type de bitume",
    vectuelMapPOIFieldRefineryUpDate: "Mise à jour",

    vectuelMapPOIFieldCircularEconomyLabel: "Label économie circulaire",


    ficheGridColumnFileExtension: "Ext.",
    ficheGridColumnFicheTitle: "Titre du document",
    ficheGridColumnFicheCategory: "Catégorie",
    ficheGridColumnFicheStatus: "Phase",
    ficheGridColumnFicheCreatedAt: "Date",
    ficheGridDownloadLabel: "Télécharger",
    ficheGridZoomLabel: "Zoom",
    poiFileListUploadButton: "Ajouter document",
    poiFileListUploadButtontooltipRights: "Vous n'avez pas les droits pour deposer un document sur cet objet",
    poiFileListUploadButtontooltipMissingUe: "Impossible d'ajouter un fichier car le POI n'est rattaché à aucune UE",
    poiFileListNoDownloadPermission: "Vous n'avez pas les droits pour télécharger ce fichier",
    ToastAddFileSuccess: "Vos documents seront accessibles via la carte d'ici 24 heures",
    ToastUpdateAddressAndContourSuccess: "Modification prise en compte et effective via la carte d'ici 24 heures",
    ToastGenericTechnicalError: "Erreur technique - Veuillez contacter votre administrateur",

    itineraryTitle: "Itinéraire",
    itineraryTitleTooltip:"Itinéraire entre deux ou plusieurs points - Distance, temps de trajet, poids carbone",
    itineraryResetForm: "Réinitialiser le formulaire",
    itineraryTrip: "Voyage",
    itineraryOneWay: "Aller simple",
    itineraryReturn: "Aller retour",
    itineraryAddDestination: "Ajouter une destination",
    itineraryCalculationLabel: "Calcul d'itinéraire",
    distanceCalculationLabel: "Calcul de distance",
    carbonCalculationLabel: "Calcul de carbone",
    itineraryFastestLabel: "Le plus rapide",
    itineraryShortestLabel: "Le plus court",
    itineraryDragToReorderLabel: "Faites glisser pour réorganiser",
    itineraryAddressPlaceHolderLabel: "Choisissez la destination ou cliquez sur la carte",
    itineraryCloseLabel: "Fermer",
    itineraryInvalidAddressesErrorMessage: "Adresses incorrectes, veuillez ajouter deux adresses valides pour calculer l'itinéraire",
    itineraryGeneralErrorMessage: "Calcul de l'itinéraire impossible",
    itineraryTruckWarningMessage: "Les calculs d'itinéraires sont effectués en évitant toujours les voies et routes interdites en fonction des profils de véhicules appliqués",
    calculateDistanceErrorMessage: "Calcul de distance impossible",
    calculateCarbonErrorMessage: "Calcul de carbone impossible",
    maxCarbonErrorMessage: "Le poids de carbone maximal est atteint. Merci de saisir un poids inférieur à",
    geoLocationNotAvailable: "Géolocalisation non disponible",
    itineraryVehicleLabel: "Véhicule",
    itineraryCarLabel: "Voiture",
    itineraryTruckLabel: "Camion",
    itineraryVehicleTypeLabel: "Type de véhicule",
    itineraryFuelTypeLabel: "Type de carburant",
    itineraryTruckDefaultFuelTypeLabel: "Diesel (GnR) uniquement",
    itineraryTransportedTonsLabel: "Nb de tonnes transportées",
    itineraryTotalWeight: "Poids Total en charge",
    itineraryHazardousMaterials: "Matières dangereuses",
    itineraryVehicleParameters: "Paramètres du véhicule",
    itineraryTruckProfileSaveSuccess: "Profil sauvegardé",

    itineraryTruckWeightValue1: "Inférieur ou égal à 7,5T", // 7,5
    itineraryTruckWeightValue2: "Entre 7,5T et 12T", // 12
    itineraryTruckWeightValue3: "Entre 12T et 19T", // 19
    itineraryTruckWeightValue4: "Entre 19T et 26T", // 26
    itineraryTruckWeightValue5: "Entre 26T et 32T", // 32
    itineraryTruckWeightValue6: "Supérieur à 32T", // 40
    
    itineraryTruckHazardousMaterialNone: "Aucune",
    itineraryTruckHazardousMaterialGeneral: "Divers",
    itineraryTruckHazardousMaterialExplosive: "Explosifs",
    itineraryTruckHazardousMaterialHarmfulToWater: "Nuisibles à l'eau",

    isochronousTitle: "Isochrone",
    IsodistanceTitle: "Isodistance",
    IsocarbonTitle: "Isocarbone",
    weatherFormTilte: "Météo",
    isochronousTitleTooltip:"Courbe reliant tous les points situés à un laps de temps identique d'une même adresse",
    IsodistanceTitleTooltip:"Courbe reliant tous les points situés à une distance identique d'une même adresse",
    IsocarbonTitleTooltip:"Courbe reliant tous les points situés à une quantité équivalente d'émissions de carbone d'une même adresse",

    weatherFormAddress:"Adresse",
    weatherFormPeriod:"Période",
    weatherFormUnit:"Système d'unités",
    weatherFormPeriodToday:"Aujourd'hui",
    weatherFormPeriodYesterday:"Hier",
    weatherFormPeriodTomorrow:"Demain",
    weatherFormPeriodLastSevenDays:"7 derniers jours",
    weatherFormPeriodLastMonth:"30 derniers jours",
    weatherFormPeriodNextSevenDays:"7 prochains jours",
    weatherFormPeriodPlaceholder:"Sélectionnez une période",
    weatherFormUnitPlaceholder:"Sélectionnez un système d'unités",
    weatherFormUnitMetric:"Metric (°C, km)",
    weatherFormUnitUk:"UK (°C, miles)",
    weatherFormUnitUS:"US (°F, miles)",
    weatherfromButton:"Voir les résultats",

    weatherTableDate:"Date",
    weatherTableAverage:"Température moyenne ",
    weatherTableTempMax:"Température maximale",
    weatherTableTempMin:"Température minimale",
    weatherTableFeltAvg:"Température ressentie moyenne",
    weatherTableFeltMax:"Température ressentie maximale",
    weatherTableFeltMin:"Température ressentie minimale",
    weatherTableHumidity:"Humidité (%)",
    weatherTablePrecipitation:"Précipitations",
    weatherTablePrecipProbability:"Précipitations - Probabilité (%)",
    weatherTablePrecipType:"Type de précipitations",
    weatherTableSnow:"Neige (%)",
    weatherTableSnowDepth:"Neige - Profondeur ",
    weatherTableWindSpeed:"Vitesse du vent ",
    weatherTablePressure:"Pression (hPa)",
    weatherTableCloudCover:"Couverture nuageuse (%)",
    weatherTableSolarRadition:"Radiation solaire (W/m2)",
    weatherTableSolarEnergy:"Energie solaire (MJ/m2)",
    weatherTableUvIndex:"Indice UV",
    weatherTableSunrise:"Lever du soleil",
    weatherTableSunset:"Coucher du soleil",
    weatherTableWeatherCondition:"Conditions climatique",

    weatherTabDaily:"Journalier",
    weatherTabHourly:"Horaire",
    weatherTabStations:"Stations",

    weatherTableStationsName:"Nom",
    weatherTableStationsId:"Identifiant",
    weatherTableStationsDistance:"Distance",
    weatherTableStationsLat:"Latitude",
    weatherTableStationsLng:"Longitude",

    inexZoomToDisplay: "Réduire la zone pour plus de détails",
    inexLayerLabel: "Parcelles",
    inexParcelLabel: "Parcelle",
    inexSiteLabel: "Site",
    inexTabDescription: "Description",
    inexTabLandRegistry: "Foncier",
    inexTabUrbanism: "Urbanisme",
    inexTabEnvironment: "Environnement",
    inexTabInfrastructures: "Infrastructures",
    inexTabContact: "Contact",
    inexSeeDetailButton: "Voir la fiche détaillée",
    inexSeeDetailButtonAlertMessage: "Données INEX à requête payante",
    inexDetailFieldIdentifier: "Identifiant",
    inexDetailFieldSurface: "Surface",
    inexDetailFieldCommune: "Commune",
    inexDetailFieldUpdateDate: "Date de mise à jour",
    inexDetailFieldNaceCode: "Code NACE",
    inexDetailSubtitleBuildings: "Bâtiments",
    inexDetailSubtitleBuilding: "Bâtiment",
    inexDetailBuildingTotalSurface: "Surface totale",
    inexDetailBuildingExploitableSurface: "Surface exploitable",
    inexDetailBuildingHeight: "Hauteur",
    inexDetailBuildingType: "Type",
    inexDetailBuildingDeltaRoofHeight: "Delta toit (hauteur max - min)",

    inexDetailSubtitleLandRegistrySituation: "Situation foncière",
    inexDetailSubtitleLandCover: "Couverture du sol",
    inexDetailSubtitleLastParcelSales: "Dernières ventes sur la parcelle (depuis 2017)",
    inexDetailSubtitleMeanPriceParcelSection: "Prix moyen au m² (Section Cadastrale)",
    inexDetailSubtitleOwners: "Propriétaires",

    inexDetailFieldParcelLandMeanPriceCityWithBuilding: "Prix moyen sur la commune (terrain bâti)",
    inexDetailFieldParcelLandMeanPriceCityWithoutBuilding: "Prix moyen sur la commune (terrain non bâti)",
    inexDetailFieldParcelLandMeanPriceSectionWithBuilding: "Prix moyen sur la section (terrain bâti)",
    inexDetailFieldParcelLandMeanPriceSectionWithoutBuilding: "Prix moyen sur la section (terrain non bâti)",

    inexDetailLastParcelSalesPrice: "Prix",
    inexDetailLastParcelSalesSurface: "Surface",
    inexDetailLastParcelSalesYear: "Année",
    inexDetailLastParcelSalesAddress: "Adresse",
    inexDetailFieldParcelOwnerGroup: "Groupe de personnes",
    inexDetailSubtitleInfrastructureTransport: "Infrastructures de transport",
    inexDetailFieldNationalRoad: "Route Nationale",
    inexDetailFieldDepartmentalRoad: "Route Départementale",
    inexDetailFieldHighway: "Autoroute",
    inexDetailFieldRailwayJunction: "Noeud ferroviaire",

    inexDetailDocumentationLabel: "Documentation",
    inexDetailSubtitleParcelEnvGeneral: "Général",
    inexDetailFieldParcelEnvFirstHouse: "1ère habitation",
    inexDetailSubtitleParcelEnvAgriculturalArea: "Zone agricole",
    inexDetailFieldParcelEnvCultureType: "Type de culture",
    inexDetailFieldParcelEnvCompensatoryMeasures: "Mesures compensatoires",
    inexDetailSubtitleParcelEnvNaturalZones10km: "Zones naturelles à moins de 10 kms",
    inexDetailTooltipZnieff: "ZNIEFF 1 : secteurs de grand intérêt biologique ou écologique; ZNIEFF 2 : Grands ensembles naturels riches et peu modifiés",
    znieffLabel: "ZNIEFF",
    inexDetailFieldParcelEnvNaturalPark: "Parc Naturel régional",
    inexDetailFieldParcelEnvNationalPark: "Parc National",
    inexDetailFieldParcelEnvNaturalReserve: "Réserve naturelle",
    inexNatura2000Label: "Natura 2000",
    inexApbLabel: "APB (Arrêtés de biotope)",
    inexDetailFieldParcelEnvEcoCorridor: "Corridor écologique (SRADDET)",
    inexDetailSubtitleParcelUrbanismMainDocuments: "Principaux documents",
    inexDetailSubtitleParcelUrbanismDocuments: "Documents d'urbanisme",
    inexDetailSubtitleParcelUrbanismOtherDocuments: "Autres documents",
    inexDetailSubtitleParcelUrbanismBuildingPermit: "Permis de construire",
    inexDetailFieldParcelUrbanismZoning: "Zonage",
    inexScotLabel: "SCOT",
    inexScotTooltip: "Schéma de COhérence Territoriale",

    inexDetailParcelBuildingPermitDepositDate: "Date de dépôt",
    inexDetailParcelBuildingPermitDeliveryDate: "Date de livraison",
    inexDetailParcelBuildingPermitOperationType: "Type d'opération",
    inexDetailParcelBuildingPermitSurfaceDemolition: "Surface de démolition",
    inexDetailParcelBuildingPermitSurfaceConstruction: "Surface de construction",
    inexDetailParcelBuildingPermitProjectOwnerLabel: "Propriétaire du projet",
    inexDetailParcelBuildingPermitProjectOwnerSiret: "Siret",
    inexDetailParcelBuildingPermitProjectOwnerName: "Nom",
    inexDetailParcelBuildingPermitProjectOwnerPhone: "Téléphone",
    inexDetailParcelBuildingPermitStartingDate: "Date de démarrage",
    inexDetailParcelBuildingPermitBuildingType: "Type de bâtiment",
    inexDetailParcelBuildingPermitProjectOwnerContact: "Contact",
    inexDetailParcelBuildingPermitSurfaceRenovation: "Surface de rénovation",

    inexSiteFamilyExutoire: "Exutoires",
    inexSiteFamilyFriche: "Sites particuliers",
    inexSiteFamilyFournisseur: "Autres concurrents",
    inexSiteFamilyConcurrent: "Concurrents",

    inexSiteSubfamilyTitleCentresTri: "Centres de tri",
    inexSiteSubfamilyTooltipCentresTri: 'Centres de tri des déchets. Zoomer pour afficher la donnée',
    inexSiteSubfamilyTitleDechetteriesPubliques: "Déchetteries publiques",
    inexSiteSubfamilyTooltipDechetteriesPubliques: "Déchetteries publiques. Zoomer pour afficher la donnée - Source: SINOE",
    inexSiteSubfamilyTitleAnciensSitesIndustriels: "Anciens sites industriels et activités de service",
    inexSiteSubfamilyTooltipAnciensSitesIndustriels: "Base de données des anciens sites industriels et activités de services. Zoomer pour afficher la donnée - Source: BASIAS",
    inexSiteSubfamilyTitleSitesEtSolsPollues: "Sites et sols pollués",
    inexSiteSubfamilyTooltipSitesEtSolsPollues: "Information de l'administration concernant des pollutions suspectées ou avérées. Zoomer pour afficher la donnée - Source: BASOL",
    inexSiteSubfamilyTitleSitesICPE: "Sites ICPE",
    inexSiteSubfamilyTooltipSitesICPE: "Sites des installations classées de protection de l'environnement. Zoomer pour afficher la donnée - Source: Géorisques",
    inexSiteSubfamilyTitleCentresTraitementDechetsBTP_FFB: "Centres de traitement des déchets du BTP",
    inexSiteSubfamilyTooltipCentresTraitementDechetsBTP_FFB: "Points de collecte dans toute la France, visant à faciliter la valorisation et le recyclage des déchets du BTP. Zoomer pour afficher la donnée - Source: dechets-chantier.ffbatiment.fr",
    inexSiteSubfamilyTitleFriches: "Friches",
    inexSiteSubfamilyTooltipFriches: "Données sur les friches (industrielles, commerciales, d’habitat…). Zoomer pour afficher la donnée - Source: CARTOFRICHES",
    inexSiteSubfamilyTitleISDI_ISDND: "ISDI & ISDND",
    inexSiteSubfamilyTooltipISDI_ISDND: "Installation de stockage de déchets inertes et Installation de stockage de déchets non dangereux. Zoomer pour afficher la donnée - Source: georisques.gouv.fr",
    inexSiteSubfamilyTitleCentresTraitementDechetsBTP_MATERRIO: "Centres de traitement des déchets du BTP",
    inexSiteSubfamilyTooltipCentresTraitementDechetsBTP_MATERRIO: "Points de collecte dans toute la France, visant à faciliter la valorisation et le recyclage des déchets du BTP. Zoomer pour afficher la donnée - Source: materrio.construction",

    inexLayerLabelReseauFerroviaire: "Réseaux ferroviaires",
    inexLayerLabelGares: "Gare de fret",
    inexLayerLabelZfe: "Zones à faibles émissions (ZFE)",
    inexLayerLabelPpa: "Plan de protection de l'atmosphere (PPA)",
    inexLayerLabelPort: "Ports de fret",
    inexLayerLabelZoneEtSecteurCc: "Zonages PLU et secteurs CC",
    inexLayerLabelServitudeUtilitePubliquePPR: "PPR",
    inexLayerLabelParcelleAgricole: "Parcelles agricoles (RPG)",
    inexLayerLabelZnieff1: "ZNIEFF Type 1",
    inexLayerLabelZnieff2: "ZNIEFF Type 2",
    inexLayerLabelNatura2000Habitat: 'NATURA 2000 Directive "Habitats"(SCI)',
    inexLayerLabelNatura2000Oiseaux: 'NATURA 2000 Directive "Oiseaux"(ZPS)',
    inexLayerLabelParcNational: "Parcs nationaux",
    inexLayerLabelParcNaturelRegional: "Parcs naturels régionaux",
    inexLayerLabelReserveNaturelleNationale: "Reserves naturelles",
    inexLayerLabelCorridorEcologique: "Corridor écologique (SRADDET)",
    inexLayerLabelZonesHumides: "Zones humides",
    inexLayerLabelMesureCompensatoire: "Mesures compensatoires",
    inexLayerLabelBssExport: "Banque du sous-sol",
    inexLayerLabelTronconHydrographique: "Cours d'eau",
    inexLayerLabelRteLigneAerienne: "Lignes aeriennes RTE",
    inexLayerLabelReserveNationaleChasseFauneSauvage: "Réserve nationale Chasse Faune Sauvage",
    inexLayerLabelApb: "APB (Arrêtés de biotope)",
    inexLayerLabelZppa: "ZPPA (Zones de présomption de prescription archéologique)",
    inexLayerLabelTrameCarriereMine: "Trame carrière/mine",
    inexLayerLabelZonesPatrimonialesProtegees: "Zones patrimoniales protégées",
    inexLayerLabelPatrimoineRemarquable: "Sites patrimoniaux remarquables",
    inexLayerLabelMonumentHistorique: "Abords des monuments historiques",
    inexLayerLabelServitudeUtilitePubliqueAC2: "Sites inscrits et classés",

    inexLayerRteLigneAerienneTooltip: "Ensemble des lignes aériennes du réseau public de transport d'électricité géré par RTE - Via odre.opendatasoft.com ",
    inexLayerReseauFerroviaireTooltip: "Ensemble des lignes du réseau ferré national - Via data.sncf.com",
    inexLayerZfeTooltip: "Base de données nationale des Zones à Faibles Émissions mises en place par les métropoles françaises - Via transport.data.gouv.fr",
    inexLayerPpaTooltip: "Le PPA est un outil de planification qui vise à reconquérir et à préserver la qualité de l’air sur le territoire. ",
    inexLayerPortTooltip: "Infrastructures portuaires permettant de fluidifier les échanges commerciaux, l'acheminement de fret et la connectivité entre les différentes régions du monde. ",
    inexLayerGaresTooltip: "Liste des gares de fret du Réseau Ferré National  - Via data.sncf.com",
    inexLayerZoneEtSecteurCcTooltip: "Zonages des documents d'urbanisme et secteurs des cartes communales françaises - Via geoportail-urbanisme.gouv.fr",
    inexLayerServitudeUtilitePubliquePPRTooltip: "Plans de prévention des risques naturels et assimilés et technologiques. ",
    inexLayerZppaTooltip: "Zones dans lesquelles les projets d'aménagement affectant le sous-sol sont présumés faire l'objet de prescriptions archéologiques préalablement à leur réalisation. ",
    inexLayerTrameCarriereMineTooltip: "Cadastre minier numérique - Via camino.beta.gouv.fr",
    inexLayerParcelleAgricoleTooltip: "Ensemble des îlots culturaux et des parcelles des exploitants se déclarant à la Politique Agricole Commune - Via geoservices.ign.fr",
    inexLayerZnieff1Tooltip: "Zones Naturelles d'Intérêt Écologique Faunistique et Floristique de type 1  - Via geoservices.ign.fr",
    inexLayerZnieff2Tooltip: "Zones Naturelles d'Intérêt Ecologique Faunistique et Floristique de type 2  - Via geoservices.ign.fr",
    inexLayerReserveNationaleChasseFauneSauvageTooltip: "Espaces naturels protégés en vue d'atteindre des objectifs spécifiques de conservation du patrimoine naturel  - Via geoservices.ign.fr ",
    inexLayerApbTooltip: "Actes administratifs pris en vue de préserver les habitats des espèces protégées, l’équilibre biologique ou la fonctionnalité des milieux. ",
    inexLayerNatura2000HabitatTooltip: "Zones Spéciales de Conservation (ZSC) visant la conservation des types d'habitats et des espèces animales et végétales  - Via geoservices.ign.fr",
    inexLayerNatura2000OiseauxTooltip: "Zones de Protection Spéciales (ZPS), visant la conservation des espèces d'oiseaux sauvages ou qui servent d'aires de reproduction, de mue, d'hivernage ou de zones de relais à des oiseaux migrateurs  - Via geoservices.ign.fr",
    inexLayerParcNationalTooltip: "Espaces protégés soumis à une réglementation spécifique (articles L331 et R331 du code de l’environnement) qui assure la sauvegarde de leur patrimoine naturel et culturel reconnu comme exceptionnel  - Via geoservices.ign.fr",
    inexLayerParcNaturelRegionalTooltip: "Les parcs naturels régionaux (PNR) concourent à la politique de protection de l'environnement, d'aménagement du territoire, de développement économique et social et d'éducation et de formation du public  - Via geoservices.ign.fr",
    inexLayerReserveNaturelleNationaleTooltip: "Espaces qui protègent des secteurs terrestres ou maritimes où la conservation de la faune, la flore, du sol, des eaux, des gisements de minéraux et de fossiles ou, plus globalement, du milieu naturel, présente une importance particulière  - Via geoservices.ign.fr",
    inexLayerCorridorEcologiqueTooltip: "Passages reliant des espaces naturels entre eux. ",
    inexLayerZonesHumidesTooltip: "Terrains, exploités ou non, habituellement inondés ou gorgés d’eau douce, salée ou saumâtre de façon permanente ou temporaire. ",
    inexLayerMesureCompensatoireTooltip: "Zones s’inscrivant dans les réglementations en matière d’environnement et sur la planification des territoires - Via catalogue.cdata.cerema.fr",
    inexLayerBssExportTooltip: "Toutes les données sur les ouvrages (forages, sondages, puits et sources) souterrains du territoire français. - Via geoservices.brgm.fr",
    inexLayerTronconHydrographiqueTooltip: "Ensemble des cours d'eau en France métropolitaine. ",
    inexLayerZonesPatrimonialesProtegeesTooltip: "Zones patrimoniales protégées. ",
    inexLayerRegionsTooltip: "Toutes les régions de France - Via data.gouv.fr",
    inexLayerDepartementsTooltip: "Tous les départements de France - Via data.gouv.fr",
    inexLayerCantonsTooltip: "Ensemble des cantons électoraux uniquement (pas de pseudo-canton ou canton fictif) en France Métropolitaine et DROM - Via public.opendatasoft.com",
    inexLayerCommunesTooltip: "Toutes les communes de France, mais aussi les arrondissements - Via data.gouv.fr",
    inexLayerParcellesCadastralesTooltip: "Toutes les parcelles cadastrales en France - Via api.gouv.fr",
    inexLayerPatrimoineRemarquableTooltip: "Sites patrimoniaux remarquables.",
    inexLayerMonumentHistoriqueTooltip: "Abords des monuments historiques.",
    inexLayerServitudeUtilitePubliqueAC2Tooltip: "Sites inscrits et classés.",

    othersWeatherLabel: "Météo",
      
    echoStandardQuestion: "Standard environnement",
    echoWaterStress: "Stress hydrique",
    echoWaterDischarges: "Rejets en eau",
    echoWasteManagement: "Gestion des déchets",
    echoBiodiversity: "Biodiversité",
    echoLocalDialogue: "Dialogue local",
    echoPagintationcampagne: "Campagne",

    echoStandardQuestionTooltip: { 
        intro: [
            "% de OUI aux questions de la check-list environnement identifiées comme « Standards Environnement » sur lesquelles une priorité doit être donné sur les sites."
        ], 
        content: []
    },
    echoWaterStressTooltip: { 
        intro: [
            "Si le site est localisé en zone de stress hydrique extrêmement élevé, un plan d'action est mis en place afin de limiter la consommation d'eau externe :"
        ], 
        content: [
            "Si 100% : OUI",
            "Si 0% : NON",
            "Si « vide » : Non applicable",
        ]
    },
    echoWaterDischargesTooltip: { 
        intro: [
            "% de OUI aux questions sur la maitrise des rejets en eau pour limiter toute pollution. Certaines questions ne sont applicables qu’à certaines activités.",
            "Pour les zones du site avec risque de pollution des eaux et des sols (manipulation/stockage de produits liquides dangereux) :"
        ], 
        content: [
            "La zone est imperméabilisée",
            "Présence d'un séparateur hydrocarbure fonctionnel et efficace",
            "Entretien annuel du séparateur hydrocarbure",
            "Pour les installations de dépotage ou de chargement maritime ou fluvial, présence d'un bras marine évitant les tuyaux flexibles et permettant une fermeture automatique passive des canalisations amont et aval en cas de rupture ou de désassemblage.",
            "Des produits liquides dangereux ne peuvent pas être évacués vers les réseaux d’eaux pluviales ou usées ou le milieu naturel, même de manière accidentelle",
            "Vérification de l’adéquation entre les produits rejetés (en particulier à partir des éviers) et le traitement (même hors site) et/ou avec les critères d'acceptation du réseau d'eaux usées",
            "A l'intérieur de l'atelier, il n'y a pas de point d’évacuation vers le réseau d’eaux pluviales",
            "Présence d'un dispositif permettant d'isoler le site du milieu extérieur (réseau d'eaux pluviales, cours d'eau, terrain voisin, etc.) en cas de déversement accidentel ou d'un incendie.",
            "Vérification annuelle du fonctionnement du dispositif permettant d'isoler le site du milieu extérieur en cas de déversement accidentel ou d'un incendie.",
            "Les eaux de lavage du hall de fabrication et de rinçage des équipements font l'objet d'un traitement adapté compte-tenu de leur composition et des critères d'acceptation des réseaux d'eaux pluviales et/ou usées.",
            "L'eau utilisée pour le lavage des matériaux fait l'objet d'un traitement adapté avant rejet ou réutilisation. Les résidus solides issus du dispositif de traitement sont traités ou recyclés.",
            "L'eau utilisée pour les opérations de lavage et rinçage (malaxeur, toupies, etc.) fait l'objet d'un traitement adapté avant rejet ou réutilisation. Les résidus solides issus du dispositif de traitement sont traités ou recyclés.",
            "Réalisation d'analyses annuelles sur les eaux rejetées en sortie du séparateur hydrocarbure, du dispositif de traitement, des eaux d’exhaure.",
            "Toutes les analyses de rejets en eaux sont conformes à la réglementation locale (arrêté, permis de rejets, etc.)",
            "Existence et respect d'un plan d'entretien du dispositif de traitement individuel des eaux usées (fosse septique, etc.)",
        ]
    },
    echoWasteManagementTooltip: { 
        intro: [
            "% de OUI aux questionssur la gestion des déchets sur la base des 5 éléments suivants :"
        ], 
        content: [
            "Registre de suivi des déchets permettant la vérification de la conformité des filières de traitement ou d’élimination",
            "Registre de suivi des déchets permettant la traçabilité jusqu’à l'exutoire final du déchet (élimination ou valorisation)",
            "Propreté de la zone de collecte des déchets",
            "Étiquetage des contenants de tri des déchets",
            "Tri des déchets correctement réalisé : déchets dangereux séparés des déchets non dangereux",
        ]
    },
    echoBiodiversityTooltip: { 
        intro: [
            "% de OUI aux questions sur la mise en place d’action(s) en faveur de la biodiversité sur la base des 3 éléments suivants :"
        ], 
        content: [
            "Mise en place d'action(s) en faveur de la biodiversité sur le site",
            "Accompagnement d'action(s) par un expert (écologue, association locale, association nationale, etc.)",
            "Communication pédagogique sur action(s) en interne et/ou en externe",
        ]
    },
    echoLocalDialogueTooltip: { 
        intro: [
            "% de OUI aux questions sur la mise en place d’une structure de dialogue local sur la base des 4 éléments suivants :"
        ], 
        content: [
            "Existence d'une rencontre de communication de moins d'un an avec les riverains (particuliers, agriculteurs, industriels, etc.)",
            "Existence d'une rencontre de communication de moins d'un an avec les autorités locales (administrationsspécialisées et municipales)",
            "Un système d'enregistrement des plaintes ou demandes extérieures est en place et est systématiquement utilisé",
            "Existence de réponses formalisées du site aux plaintes ou demandes extérieures",
        ]
    },
    
    chooseCountry: "Choisir un pays: ",
    Maroc: "Maroc",
    France: "France",
    England: "Angleterre",

    importLayer: "Importer une couche",
    importLayerNameLabel: "Nom de la couche :",
    importLayerCancelButton: "Annuler",
    importLayerUploadButton: "Charger",
    importLayerFileSizeExeededError: "Taille maximum du fichier 20 Mo",
    importLayerNormesNotValidError: "Système de projection supporté : WGS84 EPSG 4326",
    importLayerFileContentError: "Erreur de lecture du fichier, veuillez vérifier son contenu",
    importLayerSaveError: "Une erreur c'est produite lors de la sauvegarde de la couche importée",
    importLayerTooltip: "Importez des couches geojson de moins de 20 Mo - Projection: EPSG 4326 WGS 84",
    importLayerFileError: "Erreur: le fichier n'a pas pu être importé",
    importLayerFileCoordinatesRangeError: "Veuillez vérifier les valeurs des coordonnées",

    deleteLayerDialogTitle:"Supprimer la Couche",
    deleteLayerDialogContent:"Voulez-vous vraiment supprimer cette Couche ?",
    deleteLayerErrorMessage: "Une erreur est survenue à la suppression de la Couche.",
    editLayerDialogTitle:"Modifier nom de la Couche",
    editLayerDialogContent:"Entrez le nouveau nom de la Couche",
    editLayerDialogLayerName:"Nom de la Couche",
    editLayerErrorMessage: "Une erreur est survenue à la modification du nom de la Couche.",

    inexButtonAccess: "Accéder",

    parisGamesMapLabel: "Paris 2024",
    parisGamesMarkersRoadsLabel: "Points et routes des jeux",
    parisGamesLayerLabel: "Site des Jeux",
    parisGamesLayerTooltip: "Sites des jeux de Paris, cliquez sur les points pour obtenir les informations",
    parisGamesSiteNameLabel: "Nom: ",
    parisGamesSiteAddressLabel: "Adresse: ",
    parisGamesSiteCategoryLabel: "Catégorie: ",
    parisGamesSiteCityLabel: "Ville: ",
    parisGamesSiteClassicSportsLabel: "Sports Classiques: ",
    parisGamesSiteParaSportsLabel: "Sports Para:",
    parisGamesRoadsLabel: "Itinéraires et voies impactés",
    parisGamesRoadsTooltip: "Ces itinéraires ne comprennent qu’une seule voie sur l’axe routier, elles sont réservées au transport des accrédités ainsi qu’aux véhicules de secours et de sécurité, les taxis, les ambulances ou encore les transports en commun. Les autres voies sont accessibles à tous les autres véhicules.Les itinéraires des jeux sont des trajets recommandés pour les véhicules accrédités, mais ne constituent pas des voies réservées.",
    parisGamesRoadsDatesLabel: "Dates: ",
    parisGamesRoadsZoneLabel: "Zones: ",
    
    parisGamesForecastLabel: "Anticiper les jeux",
    parisGamesDisclaimer: "Les données affichées sont celles communiquées par la Préfecture de Police de Paris et par le Ministère de l'intérieur. Des impacts supplémentaires peuvent être à prévoir localement.",
    parisGamesRoadsForecastSubTitle: "Voitures: aires d’accès restreint",
    parisGamesRoadsForecastDatePickerLabel: "Voir les zones d’accès restreint à la date du:",
    parisGamesRoadsForecastHideButtonLabel: "Masquer",
    parisGamesRoadsForecastShowButtonLabel: "Montrer",
    parisGamesRoadsForecastText:"Les zones affichées à l’écran correspondent aux aires dans lesquelles les accès sont réglementés et soumis à une dérogation. Les restrictions d'accès sont uniquement appliquées entre 6h00 et 00h00,",
    parisGamesRoadsForecastAskPassJeux: "Demander une dérogation d’accès Pass Jeux",
    
    parisGamesTransportsForecastSubTitle: "Transports en Commun: météo du trafic",
    parisGamesTransportsForecastDatePickerLabel: "Voir la météo du trafic en date et heure du:",
    parisGamesTransportsForecastHideButtonLabel: "Masquer",
    parisGamesTransportsForecastShowButtonLabel: "Montrer",
    parisGamesTransportsForecastText: [
        "Les lignes de transport en commun les plus impactées en Île-de-France :",
        "- Métros : 5, 6, 7, 8, 9, 10, 12, 13 et 14 ;",
        "- Tram : T3a et T3b",
        "- RER B, C et D",
        "- Transilien : ligne J (direction Ermont Eaubonne), ligne L (direction Versailles Rive Droite), ligne N, ligne P (direction Meaux) et ligne U",
        "Les stations de métro fermées :",
        "- Métro 1 :",
        "\" Champs-Elysées - Clémenceau \" - du 01/07 au 21/09",
        "\" Concorde \" et \" Tuileries \" - du 17/06 au 21/09",
        "- Métro 8 : \" Concorde \" - du 17/06 au 21/09",
        "- Métro 12 : \" Concorde \" - du 17/05 au 21/09",
        "- Métro 13 : \" Champs-Elysées - Clémenceau \" - du 01/07 au 21/09",
        "Les stations de tram non-desservies :",
        "- Tram T2 : \" Porte d'Issy \" et \" Porte de Versailles \" - du 25/07 au 11/08 et du 29/08 au 07/09",
        "- Tram T3a : \" Porte de Versailles \" - du 25/07 au 11/08 et du 29/08 au 07/09",
        "- Tram T3b : \" Colette Besson \" - du 27/07 au 10/08 et du 29/08 au 08/09"
    ],
    parisGamesTransportsStationStatusClosed:"Station fermée",
    parisGamesTransportsStationStatusGreen:"Station à privilégier",
    parisGamesTransportsStationStatusYellow:"Station très fréquentée",
    parisGamesTransportsStationStatusOrange:"Station extrêmement fréquentée",
    parisGamesTransportsStationStatusRed:"Station à éviter",
    parisGamesTransportsStationStatusNoImpact:"Station non-impactée par les Jeux",
};
