//strings.ts
import { enStrings } from './assets/locales/en/translation';
import { frStrings } from './assets/locales/fr/translation';
import LocalizedStrings, { LocalizedStringsMethods } from 'react-localization';

export interface LocaleStrings extends LocalizedStringsMethods {
    ponctuationColon: string,
    color:string,
    stroke:string,
    speed: string,
    logOut: string,
    accessDenied: string,
    firstConnection: string,
    noPermissions: string,
    lastDocs: string,
    displayDocsAllAgencies: string,
    fileExtension: string,
    fileName: string,
    agency: string,
    territory: string,
    company: string,
    productionSite: string,
    physicalSite: string,
    contract: string,
    author: string,
    lastUpdate: string,
    delete: string,
    showDetail: string,
    alert: string,
    yes: string,
    no: string,
    absent: string,
    present: string,
    state: string,
    loadingOk: string,
    loadingKo: string,
    ignoredDoc: string,
    dragAndDrop: string,
    btnEditAddressPerimeterEcontractJobSite: string,
    titleEditAddressPerimeterEcontractJobSite: string,
    save: string,
    cancel: string,
    patrimony: string,
    dropZone: string,
    map: string,
    formTitle: string,
    exit: string,
    ignoreSheet: string,
    createNewToggle: string,
    structureType: string,
    label: string,
    id: string,
    address: string,
    email: string,
    phoneNumber: string,
    webLink: string,
    labelContract_mnd: string,
    labelCodeContract_mnd: string,
    TooltipInitialLocation: string,
    labelCustomContract_mnd: string,
    searchBar: string,
    searchBarCustomContract: string,
    searchBarNoResult: string,
    searchBarNoResultHasMinLength: string,
    contractCode: string,
    contractClient: string,
    contractState: string,
    documentInformation: string,
    documentTitle_mnd: string,
    agency_mnd: string,
    documentCreationTime: string,
    documentState: string,
    projection: string,
    category: string,
    dataType: string,
    intellectualProperty: string,
    localization: string,
    locationSearchBar: string,
    prevDoc: string,
    nxtDoc: string,
    loaded: string,
    next: string,
    summTitle: string,
    finalize: string,
    noDoc: string,
    loadedDocs: string,
    deletionDialogTitle: string,
    deletionDialogContent: string,
    deletionDialogConfirmation: string,
    deletionDialogCancelation: string,
    restrictionshowDetailMessage: string,
    preventShareToOtherStructures: string,
    businessCheck: string,
    jobCheck: string,
    jobSiteIdentifier: string,
    jobTooltip: string,
    contractTooltip: string,
    jobTitle: string,
    edit: string,
    confirmEdition: string,
    rename:string,
    noRecords: string,
    tags: string,
    addTag: string,
    keepTagForFutureFiles: string,
    forgeViewer: string,
    fileViewer: string,
    uploadSearchLabel: string,
    uploadToggleButtonMyDocuments: string,
    uploadToggleButtonMyAgencies: string,
    uploadPopUpButtonBack: string,
    unavailableFileErrorMessage: string,
    fileDownloadAccessErrorMessage: string,
    unavailableFicheErrorMessage: string,
    deleteFileSuccessMessage: string,
    deleteFileErrorMessage: string,
    unvailableGpsCoordinatesForBusinessContractOrJobSiteErrorMessage: string,
    unvailableGpsCoordinatesForOtherStructureTypesErrorMessage: string,
    invalidFileSizeErrorMessage: string,
    invalidFileExtensionErrorMessage: string,
    requestUserRefreshPage: string,
    popUpPolygonInformationMessage: string,
    latitude: string,
    longitude: string,
    opacity: string,
    minutes: string,
    kilometers: string,
    kilometersLabel:string,
    Meters: string,
    Feet: string,
    Yard: string,
    Miles: string,
    SqMeters:string,
    SqKilometres:string,
    SqFeet:string,
    Acres: string,
    SqMiles:string,
    kilograms: string,
    saveChoice: string,
    noData: string,
    initialLocation: string,
    newLocation: string,
    preserveLayerDisplay: string,
    layerName: string,
    optionalLayerName: string,
    myLayer: string,
    structures: string,
    siren: string,
    name: string,
    genericErrorMessage: string,
    create: string,
    firstName: string,
    profil: string,
    language: string,
    myProfile: string,
    height: string,
    length: string,
    width: string,
    tons: string,
    regions: string,
    departments: string,
    cantons: string,
    municipalities: string,


    help: string,
    tutorials: string,
    webSite: string,
    guidedTour: string,

    poiCorrectionFormTitle: string,
    poiCorrectionFormSubtitle: string,
    poiCorrectionFormIdFieldLabel: string,
    poiCorrectionFormDescriptionFieldLabel: string,
    poiCorrectionFormValidateButton: string,
    poiCorrectionFormSuccessMessage: string,
    poiCorrectionFormErrorMessage: string,
    poiEditAddressPerimeterEcontractButton: string,
    poiEditAddressPerimeterJobButton: string,
    poiEditAddressTooltip: string,
    poiEditPerimeterTooltip: string,

    poiSearchTooltip: string,

    vectuelMapLoading: string,
    vectuelMapLoadingError: string,
    vectuelMapSearchAddressGroup: string,
    vectuelMapSearchPoiGroup: string,
    vectuelMapItemsResult: string,
    vectuelMapSearchLabel: string,
    vectuelMapPOIInfosTab: string,
    vectuelMapPOIContactsTab: string,
    vectuelMapPOIFilesTab: string,

    vectuelMapObjectType: string,
    vectuelMapUeTitle: string,
    vectuelMapLevel2Activities: string,
    vectuelMapDateYear: string,
    vectuelMapDocumentDataCategory: string,
    vectuelMapDocumentExtension: string,
    vectuelMapEquipmentType: string, 
    vectuelMapErtTitle: string,
    vectuelMapCircularEconomyGrouping: string,
    vectuelMapSupplierCompany: string,
    vectuelMapSupplierSolvencyScore: string,
    vectuelMapClient: string,
    vectuelMapJobsiteStatus: string,
    vectuelMapProductId: string,
    vectuelMapProductLabel: string,
    vectuelMapProductSpecialCode: string,
    vectuelMapEcontractStatus: string,
    vectuelMapPhysicalSiteType: string,

    vectuelMapFilterTooltipUeTitle: string,
    vectuelMapFilterTooltipLevel2Activities: string,
    vectuelMapFilterTooltipDateYear: string,
    vectuelMapFilterTooltipDocumentDataCategory: string,
    vectuelMapFilterTooltipDocumentExtension: string,
    vectuelMapFilterTooltipEquipmentType: string, 
    vectuelMapFilterTooltipErtTitle: string,
    vectuelMapFilterTooltipCircularEconomyGrouping: string,
    vectuelMapFilterTooltipSupplierCompany: string,
    vectuelMapFilterTooltipSupplierSolvencyScore: string,
    vectuelMapFilterTooltipClient: string,
    vectuelMapFilterTooltipJobsiteStatus: string,
    vectuelMapFilterTooltipProductId: string,
    vectuelMapFilterTooltipProductLabel: string,
    vectuelMapFilterTooltipProductSpecialCode: string,
    vectuelMapFilterTooltipEcontractStatus: string,
    vectuelMapFilterTooltipPhysicalSiteType: string,

    vectuelMapFilterSubmitButton: string,
    vectuelMapFiltersChooseToDisplay: string,
    vectuelMapFiltersDisplaySelected: string,
    vectuelMapPOIFieldId: string,
    vectuelMapPOIFieldLabel: string,
    vectuelMapPOIFieldShape: string,
    vectuelMapPOIFieldLength: string,
    vectuelMapPOIFieldTotalPop: string,
    vectuelMapPOIFieldStatus: string,
    vectuelMapPOIFieldPop94: string,
    vectuelMapPOIFieldPop04: string,
    vectuelMapPOIFieldHouseholdNumber: string,
    vectuelMapPOIFieldOsmId: string,
    vectuelMapPOIFielCode: string,
    vectuelMapPOIFielClass: string,
    vectuelMapPOIFieloneWay: string,
    vectuelMapPOIFielMaxSpeed: string,
    vectuelMapPOIFielBridge: string,
    vectuelMapPOIFielTunnel: string,
    vectuelMapPOIFieldContract: string,
    vectuelMapPOIFieldErt: string,
    vectuelMapPOIFieldUE: string,
    vectuelMapPOIFieldSJ: string,
    vectuelMapPOIFieldAddress: string,
    vectuelMapPOIFieldActivities: string,
    vectuelMapPOIFieldInfracareLink: string,
    vectuelMapPOIFieldClient: string,
    vectuelMapPOIFieldJobsiteAmount: string,
    vectuelMapPOIFieldMainContractManager: string,
    vectuelMapPOIFieldMainForeman: string,
    vectuelMapPOIFieldPlannedStartDate: string,
    vectuelMapPOIFieldJobsiteStatus: string,

    vectuelMapPOIFieldJobsiteProducts: string,
    vectuelMapPOIFieldJobsiteProductId: string,
    vectuelMapPOIFieldJobsiteProductLabel: string,
    vectuelMapPOIFieldJobsiteProductSegmentation: string,
    vectuelMapPOIFieldJobsiteProductStartDate: string,
    vectuelMapPOIFieldJobsiteProductEndDate: string,
    vectuelMapPOIFieldJobsiteProductQuantity: string,
    vectuelMapPOIFieldJobsiteProductUnits: string,
    vectuelMapPOIFieldJobsiteShowProductsDetails: string,
    vectuelMapPOIFieldJobsiteLocateProduct: string,
    vectuelMapPOIFieldJobsiteLocateProductErrorMessage: string,
    vectuelMapPOIFieldJobsiteERT: string,
    vectuelMapPOIFieldJobsiteUP: string,
    vectuelMapPOIFieldJobsiteProductRock: string,
    vectuelMapPOIFieldJobsiteProductBitumen: string,
    vectuelMapPOIFieldJobsiteProductColor: string,
    vectuelMapPOIFieldJobsiteProductGranulometry: string,
    vectuelMapPOIFieldJobsiteProductAsphaltFamily: string,
    vectuelMapPOIFieldJobsiteProductClass: string,
    vectuelMapPOIFieldJobsiteProductColasSpecial: string,
    vectuelMapPOIFieldJobsiteProductTemperature: string,
    vectuelMapPOIFieldJobsiteProductTemperatureRange: string,
    vectuelMapPOIFieldJobsiteProductProductAsphaltAggregateRatio: string,
    vectuelMapPOIFieldJobsiteProductOptimized: string,
    vectuelMapPOIFieldEcontractStatus: string,
    vectuelMapPOIFieldUESites: string,
    vectuelMapPOIFieldJobsites: string,
    vectuelMapPOIFieldDocuments: string,
    vectuelMapPOIFieldEquipmentType: string,
    vectuelMapPOIFieldEquipmentBrand: string,
    vectuelMapPOIFieldEquipmentModel: string,
    vectuelMapPOIFieldEquipmentLicensePlate: string,
    vectuelMapPOIFieldGpsCoordinates: string,
    vectuelMapPOIFieldEquipmentStatus: string,
    vectuelMapPOIFieldLastMaintenanceDate: string,
    vectuelMapPOIFieldNextMaintenanceDate: string,
    vectuelMapPOIFieldSupplierSite: string,
    vectuelMapPOIFieldCompany: string,
    vectuelMapPOIFieldSupplierActivities: string,
    vectuelMapPOIFieldSiret: string,
    vectuelMapPOIFieldSupplierSolvencyScore: string,
    vectuelMapPOIFieldSupplierContacts: string,
    vectuelMapPOIFieldJobsiteId: string,
    vectuelMapPOIFieldComments: string,
    vectuelMapPOIFieldAuscultationLastStatementDate: string,
    vectuelMapPOIFieldAusculationTotalLength: string,
    vectuelMapPOIFieldAusculationBadLength: string,
    vectuelMapPOIFieldAusculationTomonitorLength: string,
    vectuelMapPOIFieldAusculationGoodLength: string,
    vectuelMapPOIOpenCorrectionFormLabel: string,
    vectuelMapPOIOpenCorrectionFormToolTipNoUe: string,
    vectuelMapPOIEmptyAdress: string,
    vectuelMapPOIEmptyTooltip: string,
    vectuelMapPOIFieldPlanonLink: string,
    

    vectuelMapPOIProposalI: string,
    vectuelMapPOIProposalT: string,
    vectuelMapPOIUrsn: string,
    vectuelMapPOISectionRe: string,
    vectuelMapPOIRoadname: string,
    vectuelMapPOIRoadNumber: string,
    vectuelMapPOIRoadClass: string,
    vectuelMapPOICostEstimationInPounds: string,
    vectuelMapPOILengthMeter: string,
    vectuelMapPOISurfaceSquareMeter: string,
    vectuelMapPOIDamageAreaSquareMeter: string,
    vectuelMapPOIGrade1: string,
    vectuelMapPOIGrade2: string,
    vectuelMapPOIGrade3: string,
    vectuelMapPOIGrade4: string,
    vectuelMapPOIGrade5: string,
    vectuelMapPOIMajorJCT: string,
    vectuelMapPOIW3w: string,
    vectuelMapPOIGid: string,
    vectuelMapPOISurveyDate: string,
    vectuelMapPOIGrade: string,
    vectuelMapPOIGradeDesc: string,
    vectuelMapPOIDamages: string,
    vectuelMapPOIDamagesWidthMeter: string,
    vectuelMapPOIDamagesAreaSquareMeter: string,
    vectuelMapPOISurface: string,
    vectuelMapPOIWitdhMeter: string,
    vectuelMapPOIUts: string,
    vectuelMapPOILinkImage: string,
    vectuelMapPOIResurveyed: string,
    vectuelMapPOIWard: string,
    vectuelMapPOIRoadType: string,
    vectuelMapPOIJunction: string,
    vectuelMapPOIMajorJunction: string,
    vectuelMapPOIMain: string,
    vectuelMapPOIRoadOver: string,
    vectuelMapPOIOsName: string,
    vectuelMapPOICorridor: string,
    vectuelMapPOIWpa: string,
    vectuelMapPOIRadius: string,
    vectuelMapPOIAngle: string,
    vectuelMapPOIAltitude: string,
    vectuelMapPOIGradient: string,
    vectuelMapPOIAdjacency: string,

    vectuelMapPOIOpenCorrectionFormToolTipNoRight: string,
    vectuelMapPOIChantier360Label: string,
    vectuelMapPOICarbonCounterLabel: string,
    vectuelMapSelectAllFilters: string,
    vectuelMapOpenDataNationalLabel: string,
    vectuelMapOpenDataDepartmentalLabel: string,
    vectuelMapCantonaleLabel: string,
    vectuelMapColasDataLabel: string,
    vectuelMapPOIFieldCompetitorArea: string,
    vectuelMapPOIFieldInsee: string,
    vectuelMapMyLayers: string,
    vectuelMapNoLayersSelected: string,
    vectuelMapNoMarkersSelected: string,

    vectuelMapOpenDataLayerCategoryAdministrative: string,
    vectuelMapOpenDataLayerCategoryTownPlanning: string,
    vectuelMapOpenDataLayerCategoryEnvironment: string,
    vectuelMapOpenDataLayerCategoryEnergyInfrastructure: string,
    vectuelMapOpenDataLayerCategoryRoadInfrastructure: string,
    vectuelMapOpenDataLayerCategoryTransportInfrastructure: string,
    vectuelMapOpenDataLayerCategoryUrbanPlanning: string,
    
    vectuelMapDepartmental92LayerCategory: string,
    vectuelMapCwacLayerCategory: string,
    vectuelMapMyLayersCategoryItinerary: string,
    vectuelMapMyLayersCategoryCreatedLayers: string,
    vectuelMapMyImportedLayersCategory: string,
    vectuelOpenData: string,

    vectuelStreetViewLabel: string,
    vectuelMapLayersLabel: string,
    vectuelMapDisplayLabel: string,
    vectuelMapLegendLabel: string,
    vectuelMapToolsLabel: string,
    vectuelMapPlotsLabel: string,
    vectuelMapMarkersLabel: string,
    vectuelMapCalculationLabel: string,
    vectuelMapDrawingLabel: string,
    vectuelMapImportLabel: string,
    vectuelMapOthersLabel: string,
    vectuelMapDrawingClearLabel: string,
    vectuelMapDrawingLabelFeaturesList: string,
    vectuelMapDrawingLabelFeature: string,


    vectuelMapToolDrawingPoint: string
    vectuelMapToolDrawingMultiPoint: string
    vectuelMapToolDrawingLine: string
    vectuelMapToolDrawingMultiLine: string
    vectuelMapToolDrawingPolygon: string
    vectuelMapToolDrawingMultiPolygon: string
    vectuelMapToolDrawingText: string
    vectuelMapToolDrawingDrawPoint: string
    vectuelMapToolDrawingDrawLine: string
    vectuelMapToolDrawingDrawPolygon: string
    vectuelMapToolDrawingDrawText: string
    vectuelMapToolDrawingEnterName: string
    vectuelMapToolDrawingCreate: string
    vectuelMapToolDrawingExport: string
    vectuelMapToolDrawingSave: string
    vectuelMapToolDrawingSaveNew: string
    vectuelMapToolDrawingSaveNewTooltip: string
    vectuelMapToolDrawingPropertieName: string
    vectuelMapToolDrawingPropertieValue: string


    vectuelMapLayerCatchment: string,
    vectuelMapLayerClaySwellingRisk: string,
    vectuelMapLayerJobContour: string,
    vectuelMapLayerLandGeologicalRisks: string,
    vectuelMapLayerLandGeology: string,
    vectuelMapLayerLandNaturalRisks: string,
    vectuelMapLayerLandRegister: string,
    vectuelMapLayerLandRegisterPrecision: string,
    vectuelMapLayerLandSoilPermeability: string,
    vectuelMapLayerPostesHtaBt: string,
    vectuelMapLayerPostesSourcesHta: string,
    vectuelMapLayerPoteauxHtaBt: string,
    vectuelMapLayerReseauABt: string,
    vectuelMapLayerReseauAHt: string,
    vectuelMapLayerReseauBt: string,
    vectuelMapLayerReseauHta: string,
    vectuelMapLayerGazNetwork: string,
    vectuelMapLayerRoadSurvey: string,
    vectuelMapLayerColasCatchmentUe: string,
    vectuelMapLayerColasPKroute: string,
    vectuelMapLayerMapStyleOsm2D: string,
    vectuelMapLayerMapStyleSat2D: string,
    vectuelMapLayerMapStyleOsm3D: string,
    vectuelMapLayerMapStyleSat3D: string,
    vectuelMapLayerMapStyleGlobe3D: string,
    vectuelMapLayerTrees: string,
    vectuelMapLayerBusStops: string,
    vectuelMapLayerPedestrianCrossings: string,
    vectuelMapLayerTrafficSignals: string,
    vectuelMapLayerHospitals: string,
    vectuelMapLayerRetirementHomes: string,
    vectuelMapLayerParkings: string,
    vectuelMapLayerWastelands: string,
    vectuelMapLayerWasteDisposals: string,
    vectuelMapLayerStores: string,
    vectuelMapLayerBikeParkings: string,
    vectuelMapLayerBikePaths: string,
    vectuelMapLayerSchools: string,
    vectuelMapLayerPublicServices: string,
    vectuelMapLayerChargingStations: string,
    vectuelMapLayerBuildingPermits: string,
    vectuelMapLayerNoiseExposures: string,
    vectuelMapLayerPlu: string,
    vectuelMapLayerAccidents: string,
    vectuelMapLayerChargingPointsIrve: string,
    vectuelMapLayerParkingsSup500: string,
    vectuelMapLayerParkingZone: string,
    vectuelMapLayerRegions: string,
    vectuelMapLayerProvinces: string,
    vectuelMapLayerMunicipalities: string,
    vectuelMapLayerCities: string,
    vectuelMapLayerRails: string,
    vectuelMapLayerRoads: string,
    vectuelMapLayerCarriageway2022: string,
    vectuelMapLayerPatching2022: string,
    vectuelMapLayerCW2022: string,
    vectuelMapLayerCW2021: string,

    vectuelMapLayerRoadSurveyTooltip: string,
    vectuelMapLayerLandRegisterTooltip: string,
    vectuelMapLayerLandRegisterPrecisionTooltip: string,
    vectuelMapLayerLandNaturalRisksTooltip: string,
    vectuelMapLayerLandGeologicalRisksTooltip: string,
    vectuelMapLayerLandGeologyTooltip: string,
    vectuelMapLayerLandSoilPermeabilityTooltip: string,
    vectuelMapLayerClaySwellingRiskTooltip: string,
    vectuelMapLayerPostesHtaBtTooltip: string,
    vectuelMapLayerPostesSourcesHtaTooltip: string,
    vectuelMapLayerPoteauxHtaBtTooltip: string,
    vectuelMapLayerReseauABtTooltip: string,
    vectuelMapLayerReseauAHtTooltip: string,
    vectuelMapLayerReseauBtTooltip: string,
    vectuelMapLayerReseauHtaTooltip: string,
    vectuelMapLayerGazNetworkTooltip: string,
    vectuelMapLayerChargingPointsIrveTooltip: string,
    vectuelMapLayerParkingsSup500Tooltip: string,
    vectuelMapLayerParkingZoneTooltip: string,

    vectuelMapLayerPopUpCategory: string,
    vectuelMapLayerPopUpNature: string,
    vectuelMapLayerPopUpName: string,
    vectuelMapLayerPopUpPinName: string,
    vectuelMapLayerPopUpLineName: string,
    vectuelMapLayerPopUpStatus: string,
    vectuelMapLayerPopUpCreationDate: string,
    vectuelMapLayerPopUpUpdateDate: string,
    vectuelMapLayerPopUpCity: string,
    vectuelMapLayerPopUpAddress: string,
    vectuelMapLayerPopUpPollution: string,
    vectuelMapLayerPopUpType: string,
    vectuelMapLayerPopUpTypeFr: string,
    vectuelMapLayerPopUpOperator: string,
    vectuelMapLayerPopUpWebsite: string,
    vectuelMapLayerPopUpPhone: string,
    vectuelMapLayerPopUpCapacity: string,
    vectuelMapLayerPopUpAccess: string,
    vectuelMapLayerPopUpOpeningHour: string,
    vectuelMapLayerPopUpEmail: string,
    vectuelMapLayerPopUpPermitId: string,
    vectuelMapLayerPopUpLotSize: string,
    vectuelMapLayerPopUpDAUState: string,
    vectuelMapLayerPopUpDAUType: string,
    vectuelMapLayerPopUpBuilderName: string,
    vectuelMapLayerPopUpOperatorName: string,
    vectuelMapLayerPopUpNbChargingPoint: string,
    vectuelMapLayerPopUpZone: string,
    vectuelMapLayerPopUpStoppingDate: string,
    vectuelMapLayerPopUpDocRef: string,
    vectuelMapLayerPopUpId: string,
    vectuelMapLayerPopUpLabel: string,
    vectuelMapLayerPopUpZoneType: string,
    vectuelMapLayerPopUpKill: string,
    vectuelMapLayerPopUpInjured: string,
    vectuelMapLayerPopUpHospitalized: string,
    vectuelMapLayerPopUpLightInjured: string,
    vectuelMapLayerPopUpDate: string,
    vectuelMapLayerPopUpDepartmentName: string,
    vectuelMapLayerPopUpRegionName: string,
    vectuelMapLayerPopUpNaturePostesHtaBt: string,
    vectuelMapLayerPopUpNaturePostesSourcesHta: string,
    vectuelMapLayerPopUpNaturePoteauxHtaBt: string,
    vectuelMapLayerPopUpNatureReseauABt: string,
    vectuelMapLayerPopUpNatureReseauAHt: string,
    vectuelMapLayerPopUpNatureReseauBt: string,
    vectuelMapLayerPopUpNatureReseauHta: string,
    vectuelMapLayerPopUpTitlePostesHtaBt: string,
    vectuelMapLayerPopUpTitlePostesSourcesHta: string,
    vectuelMapLayerPopUpTitlePoteauxHtaBt: string,
    vectuelMapLayerPopUpTitleReseauABt: string,
    vectuelMapLayerPopUpTitleReseauAHt: string,
    vectuelMapLayerPopUpTitleReseauBt: string,
    vectuelMapLayerPopUpTitleReseauHta: string,
    vectuelMapLayerPopUpTabTitleProperties: string,
    vectuelMapLayerPopUpTabTitleInformations: string,
    vectuelMapLayerPopUpTabTitleStyle: string,
    vectuelMapLayerPopUpSizeField: string,
    vectuelMapLayerPopUpWeightField: string,
    vectuelMapLayerPopUpFontSizeField: string,
    vectuelMapLayerPopUpSizeFieldSmall: string,
    vectuelMapLayerPopUpSizeFieldNormal: string,
    vectuelMapLayerPopUpSizeFieldBig: string,
    vectuelMapLayerPopUpIconField: string,
    vectuelMapLayerPopUpDeleteButton: string,
    vectuelMapLayerPopUpBorderColor: string,
    vectuelMapLayerPopUpStroke: string,
    vectuelMapLayerPopUpAddproperty: string,
    
    vectuelMapObjectTypeFilterCategoryBoreholes: string,
    vectuelMapObjectTypeFilterCategoryColas: string,
    vectuelMapObjectTypeFilterCategoryRefineries: string,
    vectuelMapObjectTypeFilterCategorySuppliers: string,

    vectuelMapObjectTypeFilterPluralArctiqueData: string,
    vectuelMapObjectTypeFilterPluralBrgmBoreholes: string,
    vectuelMapObjectTypeFilterPluralLegalCompany: string,
    vectuelMapObjectTypeFilterPluralExploitationUnit: string,
    vectuelMapObjectTypeFilterPluralJob: string,
    vectuelMapObjectTypeFilterPluralMaterialEquipment: string,
    vectuelMapObjectTypeFilterPluralModel3d: string,
    vectuelMapObjectTypeFilterPluralProductionUnit: string,
    vectuelMapObjectTypeFilterPluralProject: string,
    vectuelMapObjectTypeFilterPluralRefinery: string,
    vectuelMapObjectTypeFilterPluralRoadStatement: string,
    vectuelMapObjectTypeFilterPluralSupplier: string,
    vectuelMapObjectTypeFilterPluralPhysicalSite: string,

    vectuelMapObjectTypeFilterPluralArctiqueDataTooltip: string,
    vectuelMapObjectTypeFilterPluralBrgmBoreholesTooltip: string,
    vectuelMapObjectTypeFilterPluralLegalCompanyTooltip: string,
    vectuelMapObjectTypeFilterPluralExploitationUnitTooltip: string,
    vectuelMapObjectTypeFilterPluralJobTooltip: string,
    vectuelMapObjectTypeFilterPluralMaterialEquipmentTooltip: string,
    vectuelMapObjectTypeFilterPluralModel3dTooltip: string,
    vectuelMapObjectTypeFilterPluralProductionUnitTooltip: string,
    vectuelMapObjectTypeFilterPluralProjectTooltip: string,
    vectuelMapObjectTypeFilterPluralRefineryTooltip: string,
    vectuelMapObjectTypeFilterPluralRoadStatementTooltip: string,
    vectuelMapObjectTypeFilterPluralSupplierTooltip: string,
    vectuelMapObjectTypeFilterPluralPhysicalSiteTooltip: string,

    ficheGridColumnFileExtension: string,
    ficheGridColumnFicheTitle: string,
    ficheGridColumnFicheCategory: string,
    ficheGridColumnFicheStatus: string,
    ficheGridColumnFicheCreatedAt: string,
    ficheGridDownloadLabel: string,
    ficheGridZoomLabel: string,
    poiFileListUploadButton: string
    poiFileListUploadButtontooltipRights: string
    poiFileListUploadButtontooltipMissingUe: string
    poiFileListNoDownloadPermission: string
    ToastAddFileSuccess: string
    ToastUpdateAddressAndContourSuccess: string
    ToastGenericTechnicalError: string

    vectuelMapPOIFieldBrgmIndex: string,
    vectuelMapPOIFieldBrgmAbbreviation: string,
    vectuelMapPOIFieldBrgmInfoTerreLink: string,
    vectuelMapPOIFieldBrgmInfoTerreLinkDeleted: string,
    vectuelMapPOIFieldBrgmHasGeoCrossSection: string,
    vectuelMapPOIFieldBrgmHasOtherGeoCrossSection: string,
    vectuelMapPOIFieldBrgmVerifiedGeoLog: string,
    vectuelMapPOIFieldBrgmWaterPoint: string,
    vectuelMapPOIFieldBrgmXEntered: string,
    vectuelMapPOIFieldBrgmYEntered: string,
    vectuelMapPOIFieldBrgmProjectionEntered: string,
    vectuelMapPOIFieldBrgmCoordinateUnitEntered: string,
    vectuelMapPOIFieldBrgmZSoil: string,
    vectuelMapPOIFieldBrgmZSoilPrecision: string,
    vectuelMapPOIFieldBrgmZAltitude: string,
    vectuelMapPOIFieldBrgmType: string,
    vectuelMapPOIFieldBrgmDepthProbe: string,
    vectuelMapPOIFieldBrgmTubeDiameter: string,
    vectuelMapPOIFieldBrgmGroundWaterDepth: string,
    vectuelMapPOIFieldBrgmSoilWaterMeasureDate: string,
    vectuelMapPOIFieldBrgmZOriginCrossSection: string,
    vectuelMapPOIFieldBrgmCrossSectionDate: string,
    vectuelMapPOIFieldBrgmEndWorkDate: string,
    vectuelMapPOIFieldBrgmGeoMapNumber: string,
    vectuelMapPOIFieldBrgmGeoMapName: string,
    vectuelMapPOIFieldBrgmPointCondition: string,
    vectuelMapPOIFieldBrgmExecutionMaterial: string,
    vectuelMapPOIFieldBrgmExploitationType: string,
    vectuelMapPOIFieldBrgmRecognitionType: string,
    vectuelMapPOIFieldBrgmDocuments: string,
    vectuelMapPOIFieldBrgmDocumentsReference: string,
    vectuelMapPOIFieldBrgmFiledDate: string,
    vectuelMapPOIFieldBrgmFillDate: string,
    vectuelMapPOIFieldBrgmLastUpdateDate: string,

    vectuelMapPOIFieldRefineryTel: string,
    vectuelMapPOIFieldRefineryFax: string,
    vectuelMapPOIFieldRefineryEmail: string,
    vectuelMapPOIFieldRefineryCapacity: string,
    vectuelMapPOIFieldRefineryVaccumDistillation: string,
    vectuelMapPOIFieldRefineryOperator: string,
    vectuelMapPOIFieldRefineryBlowing: string,
    vectuelMapPOIFieldRefineryCatalyticCracking: string,
    vectuelMapPOIFieldRefineryVisbreaking: string,
    vectuelMapPOIFieldRefineryDeasphalting: string,
    vectuelMapPOIFieldRefineryOtherUnits: string,
    vectuelMapPOIFieldRefineryProdCapacity: string,
    vectuelMapPOIFieldRefineryShortResidue: string,
    vectuelMapPOIFieldRefineryHeavyDistillate: string,
    vectuelMapPOIFieldRefineryAromaticExtract: string,
    vectuelMapPOIFieldRefineryVisbrokenResidue: string,
    vectuelMapPOIFieldRefineryOtherRawMaterials: string,
    vectuelMapPOIFieldRefineryOnLineBlending: string,
    vectuelMapPOIFieldRefineryAdditive: string,
    vectuelMapPOIFieldRefineryOtherProcess: string,
    vectuelMapPOIFieldRefineryBitumenType: string,
    vectuelMapPOIFieldRefineryUpDate: string,

    vectuelMapPOIFieldCircularEconomyLabel: string,

    vectuelMapPOILocationTypeLabel: string,

    itineraryTitle: string,
    itineraryTitleTooltip: string,
    itineraryResetForm: string,
    itineraryTrip: string,
    itineraryOneWay: string,
    itineraryReturn: string,
    itineraryAddDestination: string,
    itineraryCalculationLabel: string,
    distanceCalculationLabel: string,
    carbonCalculationLabel: string,
    itineraryFastestLabel: string,
    itineraryShortestLabel: string,
    itineraryDragToReorderLabel: string,
    itineraryAddressPlaceHolderLabel: string,
    itineraryCloseLabel: string,
    itineraryInvalidAddressesErrorMessage: string,
    itineraryGeneralErrorMessage: string,
    itineraryTruckWarningMessage: string,
    calculateDistanceErrorMessage: string,
    calculateCarbonErrorMessage: string,
    maxCarbonErrorMessage: string,
    geoLocationNotAvailable: string,
    itineraryVehicleLabel: string,
    itineraryCarLabel: string,
    itineraryTruckLabel: string,
    itineraryVehicleTypeLabel: string,
    itineraryFuelTypeLabel: string,
    itineraryTruckDefaultFuelTypeLabel: string,
    itineraryTransportedTonsLabel: string,
    itineraryTotalWeight: string,
    itineraryHazardousMaterials: string,
    itineraryVehicleParameters: string,
    itineraryTruckProfileSaveSuccess: string,

    itineraryTruckWeightValue1: string,
    itineraryTruckWeightValue2: string,
    itineraryTruckWeightValue3: string,
    itineraryTruckWeightValue4: string,
    itineraryTruckWeightValue5: string,
    itineraryTruckWeightValue6: string,
    
    itineraryTruckHazardousMaterialNone: string,
    itineraryTruckHazardousMaterialGeneral: string,
    itineraryTruckHazardousMaterialExplosive: string,
    itineraryTruckHazardousMaterialHarmfulToWater: string,

    vectuelMapPOIPropetiesEquipmentCode:string,
    vectuelMapPOIPropetiesEquipmentBrand:string,
    vectuelMapPOIPropetiesEquipmentModel:string,
    vectuelMapPOIPropetiesQuantity:string,
    vectuelMapPOIPropetiesEquipmentCodeWorkTime:string,
    vectuelMapPOIPropetiesDistance:string,
 
    isochronousTitle: string,
    IsodistanceTitle: string,
    IsocarbonTitle: string,
    isochronousTitleTooltip: string,
    IsodistanceTitleTooltip: string,
    IsocarbonTitleTooltip: string,
    weatherFormTilte: string,

    weatherFormAddress:string,
    weatherFormPeriod:string,
    weatherFormUnit:string,
    weatherFormPeriodToday:string,
    weatherFormPeriodYesterday:string,
    weatherFormPeriodTomorrow:string,
    weatherFormPeriodLastSevenDays:string,
    weatherFormPeriodLastMonth:string,
    weatherFormPeriodNextSevenDays:string,
    weatherFormPeriodPlaceholder:string,
    weatherFormUnitPlaceholder:string,
    weatherFormUnitMetric:string,
    weatherFormUnitUk:string,
    weatherFormUnitUS:string,
    weatherfromButton: string,

    weatherTableDate:string,
    weatherTableAverage:string,
    weatherTableTempMax:string,
    weatherTableTempMin:string,
    weatherTableFeltAvg:string,
    weatherTableFeltMax:string,
    weatherTableFeltMin:string,
    weatherTableHumidity:string,
    weatherTablePrecipitation:string,
    weatherTablePrecipProbability:string,
    weatherTablePrecipType:string,
    weatherTableSnow:string,
    weatherTableSnowDepth:string,
    weatherTableWindSpeed:string,
    weatherTablePressure:string,
    weatherTableCloudCover:string,
    weatherTableSolarRadition:string,
    weatherTableSolarEnergy:string,
    weatherTableUvIndex:string,
    weatherTableSunrise:string,
    weatherTableSunset:string,
    weatherTableWeatherCondition:string,

    weatherTabDaily:string,
    weatherTabHourly:string,
    weatherTabStations:string,

    weatherTableStationsName:string,
    weatherTableStationsId:string,
    weatherTableStationsDistance:string,
    weatherTableStationsLat:string,
    weatherTableStationsLng:string,

    
    inexZoomToDisplay: string,
    inexLayerLabel: string,
    inexParcelLabel: string,
    inexSiteLabel: string,
    inexTabDescription: string,
    inexTabLandRegistry: string,
    inexTabUrbanism: string,
    inexTabEnvironment: string,
    inexTabInfrastructures: string,
    inexTabContact: string,

    inexSeeDetailButton: string,
    inexSeeDetailButtonAlertMessage: string,
    inexDetailFieldIdentifier: string,
    inexDetailFieldSurface: string,
    inexDetailFieldCommune: string,
    inexDetailFieldUpdateDate: string,
    inexDetailFieldNaceCode: string,

    inexDetailSubtitleBuildings: string,
    inexDetailSubtitleBuilding: string,
    inexDetailBuildingTotalSurface: string,
    inexDetailBuildingExploitableSurface: string,
    inexDetailBuildingHeight: string,
    inexDetailBuildingType: string,
    inexDetailBuildingDeltaRoofHeight: string,

    inexDetailSubtitleLandRegistrySituation: string,
    inexDetailSubtitleLandCover: string,
    inexDetailSubtitleLastParcelSales: string,
    inexDetailSubtitleMeanPriceParcelSection: string,
    inexDetailSubtitleOwners: string,
    inexDetailFieldParcelLandMeanPriceCityWithBuilding: string,
    inexDetailFieldParcelLandMeanPriceCityWithoutBuilding: string,
    inexDetailFieldParcelLandMeanPriceSectionWithBuilding: string,
    inexDetailFieldParcelLandMeanPriceSectionWithoutBuilding: string,
    inexDetailLastParcelSalesPrice: string,
    inexDetailLastParcelSalesSurface: string,
    inexDetailLastParcelSalesYear: string,
    inexDetailLastParcelSalesAddress: string,
    inexDetailFieldParcelOwnerGroup: string,
    inexDetailSubtitleInfrastructureTransport: string,
    inexDetailFieldNationalRoad: string,
    inexDetailFieldDepartmentalRoad: string,
    inexDetailFieldHighway: string,
    inexDetailFieldRailwayJunction: string,

    inexDetailDocumentationLabel: string,
    inexDetailSubtitleParcelEnvGeneral: string,
    inexDetailFieldParcelEnvFirstHouse: string,
    inexDetailSubtitleParcelEnvAgriculturalArea: string,
    inexDetailFieldParcelEnvCultureType: string,
    inexDetailFieldParcelEnvCompensatoryMeasures: string,
    inexDetailSubtitleParcelEnvNaturalZones10km: string,
    inexDetailTooltipZnieff: string,
    znieffLabel: string,
    inexDetailFieldParcelEnvNaturalPark: string,
    inexDetailFieldParcelEnvNationalPark: string,
    inexDetailFieldParcelEnvNaturalReserve: string,
    inexNatura2000Label: string,
    inexApbLabel: string,
    inexDetailFieldParcelEnvEcoCorridor: string,
    inexDetailSubtitleParcelUrbanismMainDocuments: string,
    inexDetailSubtitleParcelUrbanismDocuments: string,
    inexDetailSubtitleParcelUrbanismOtherDocuments: string,
    inexDetailSubtitleParcelUrbanismBuildingPermit: string,
    inexDetailFieldParcelUrbanismZoning: string,
    inexScotLabel: string,
    inexScotTooltip: string,

    inexDetailParcelBuildingPermitDepositDate: string,
    inexDetailParcelBuildingPermitDeliveryDate: string,
    inexDetailParcelBuildingPermitOperationType: string,
    inexDetailParcelBuildingPermitSurfaceDemolition: string,
    inexDetailParcelBuildingPermitSurfaceConstruction: string,
    inexDetailParcelBuildingPermitSurfaceRenovation: string,
    inexDetailParcelBuildingPermitProjectOwnerLabel: string,
    inexDetailParcelBuildingPermitProjectOwnerSiret: string,
    inexDetailParcelBuildingPermitProjectOwnerName: string,
    inexDetailParcelBuildingPermitProjectOwnerPhone: string,
    inexDetailParcelBuildingPermitStartingDate: string,
    inexDetailParcelBuildingPermitBuildingType: string,
    inexDetailParcelBuildingPermitProjectOwnerContact: string,

    inexSiteFamilyExutoire: string,
    inexSiteFamilyFriche: string,
    inexSiteFamilyFournisseur: string,
    inexSiteFamilyConcurrent: string,

    inexSiteSubfamilyTitleCentresTri: string,
    inexSiteSubfamilyTooltipCentresTri: string,
    inexSiteSubfamilyTitleDechetteriesPubliques: string,
    inexSiteSubfamilyTooltipDechetteriesPubliques: string,
    inexSiteSubfamilyTitleAnciensSitesIndustriels: string,
    inexSiteSubfamilyTooltipAnciensSitesIndustriels: string,
    inexSiteSubfamilyTitleSitesEtSolsPollues: string,
    inexSiteSubfamilyTooltipSitesEtSolsPollues: string,
    inexSiteSubfamilyTitleSitesICPE: string,
    inexSiteSubfamilyTooltipSitesICPE: string,
    inexSiteSubfamilyTitleCentresTraitementDechetsBTP_FFB: string,
    inexSiteSubfamilyTooltipCentresTraitementDechetsBTP_FFB: string,
    inexSiteSubfamilyTitleFriches: string,
    inexSiteSubfamilyTooltipFriches: string,
    inexSiteSubfamilyTitleISDI_ISDND: string,
    inexSiteSubfamilyTooltipISDI_ISDND: string,
    inexSiteSubfamilyTitleCentresTraitementDechetsBTP_MATERRIO: string,
    inexSiteSubfamilyTooltipCentresTraitementDechetsBTP_MATERRIO: string,

    inexLayerLabelReseauFerroviaire: string,
    inexLayerLabelGares: string,
    inexLayerLabelZfe: string,
    inexLayerLabelPpa: string,
    inexLayerLabelPort: string,
    inexLayerLabelZoneEtSecteurCc: string,
    inexLayerLabelServitudeUtilitePubliquePPR: string,
    inexLayerLabelParcelleAgricole: string,
    inexLayerLabelZnieff1: string,
    inexLayerLabelZnieff2: string,
    inexLayerLabelNatura2000Habitat: string,
    inexLayerLabelNatura2000Oiseaux: string,
    inexLayerLabelParcNational: string,
    inexLayerLabelParcNaturelRegional: string,
    inexLayerLabelReserveNaturelleNationale: string,
    inexLayerLabelCorridorEcologique: string,
    inexLayerLabelZonesHumides: string,
    inexLayerLabelMesureCompensatoire: string,
    inexLayerLabelBssExport: string,
    inexLayerLabelTronconHydrographique: string,
    inexLayerLabelRteLigneAerienne: string,
    inexLayerLabelReserveNationaleChasseFauneSauvage: string,
    inexLayerLabelApb: string,
    inexLayerLabelZppa: string,
    inexLayerLabelTrameCarriereMine: string,
    inexLayerLabelZonesPatrimonialesProtegees: string,
    inexLayerLabelPatrimoineRemarquable: string,
    inexLayerLabelMonumentHistorique: string,
    inexLayerLabelServitudeUtilitePubliqueAC2: string,

    inexLayerRteLigneAerienneTooltip: string,
    inexLayerReseauFerroviaireTooltip: string,
    inexLayerZfeTooltip: string,
    inexLayerPpaTooltip: string,
    inexLayerPortTooltip: string,
    inexLayerGaresTooltip: string,
    inexLayerZoneEtSecteurCcTooltip: string,
    inexLayerServitudeUtilitePubliquePPRTooltip: string,
    inexLayerZppaTooltip: string,
    inexLayerTrameCarriereMineTooltip: string,
    inexLayerParcelleAgricoleTooltip: string,
    inexLayerZnieff1Tooltip: string,
    inexLayerZnieff2Tooltip: string,
    inexLayerReserveNationaleChasseFauneSauvageTooltip: string,
    inexLayerApbTooltip: string,
    inexLayerNatura2000HabitatTooltip: string,
    inexLayerNatura2000OiseauxTooltip: string,
    inexLayerParcNationalTooltip: string,
    inexLayerParcNaturelRegionalTooltip: string,
    inexLayerReserveNaturelleNationaleTooltip: string,
    inexLayerCorridorEcologiqueTooltip: string,
    inexLayerZonesHumidesTooltip: string,
    inexLayerMesureCompensatoireTooltip: string,
    inexLayerBssExportTooltip: string,
    inexLayerTronconHydrographiqueTooltip: string,
    inexLayerZonesPatrimonialesProtegeesTooltip: string,
    inexLayerRegionsTooltip: string,
    inexLayerDepartementsTooltip: string,
    inexLayerCantonsTooltip: string,
    inexLayerCommunesTooltip: string,
    inexLayerParcellesCadastralesTooltip: string,
    inexLayerPatrimoineRemarquableTooltip: string,
    inexLayerMonumentHistoriqueTooltip: string,
    inexLayerServitudeUtilitePubliqueAC2Tooltip: string,
    
    othersWeatherLabel: string,
    
    echoStandardQuestion: string,
    echoWaterStress: string,
    echoWaterDischarges: string,
    echoWasteManagement: string,
    echoBiodiversity: string,
    echoLocalDialogue: string,
    echoPagintationcampagne: string,

    echoStandardQuestionTooltip: { intro: string[], content: string[] },
    echoWaterStressTooltip: { intro: string[], content: string[] },
    echoWaterDischargesTooltip: { intro: string[], content: string[] },
    echoWasteManagementTooltip: { intro: string[], content: string[] },
    echoBiodiversityTooltip: { intro: string[], content: string[] },
    echoLocalDialogueTooltip: { intro: string[], content: string[] },

    chooseCountry: string,
    Maroc: string,
    France: string,
    England: string,

    importLayer: string,
    importLayerNameLabel: string,
    importLayerCancelButton: string,
    importLayerUploadButton: string,
    importLayerFileSizeExeededError: string,
    importLayerNormesNotValidError: string,
    importLayerFileContentError: string,
    importLayerSaveError: string,
    importLayerTooltip: string,
    importLayerFileError: string,
    importLayerFileCoordinatesRangeError: string,

    deleteLayerDialogTitle: string,
    deleteLayerDialogContent: string,
    deleteLayerErrorMessage: string,
    editLayerDialogTitle: string,
    editLayerDialogContent: string,
    editLayerDialogLayerName: string,
    editLayerErrorMessage: string,

    inexButtonAccess: string,

    parisGamesMapLabel: string,
    parisGamesMarkersRoadsLabel: string,
    parisGamesLayerLabel: string,
    parisGamesLayerTooltip: string,
    parisGamesSiteNameLabel: string,
    parisGamesSiteAddressLabel: string,
    parisGamesSiteCategoryLabel: string,
    parisGamesSiteCityLabel: string,
    parisGamesSiteClassicSportsLabel: string,
    parisGamesSiteParaSportsLabel: string,
    parisGamesRoadsLabel: string,
    parisGamesRoadsTooltip: string,
    parisGamesRoadsDatesLabel: string,
    parisGamesRoadsZoneLabel: string,
    
    parisGamesForecastLabel: string,
    parisGamesDisclaimer: string,
    
    parisGamesRoadsForecastSubTitle: string,
    parisGamesRoadsForecastDatePickerLabel: string,
    parisGamesRoadsForecastHideButtonLabel: string,
    parisGamesRoadsForecastShowButtonLabel: string,
    parisGamesRoadsForecastText: string,
    parisGamesRoadsForecastAskPassJeux: string,
    
    parisGamesTransportsForecastSubTitle: string,
    parisGamesTransportsForecastDatePickerLabel: string,
    parisGamesTransportsForecastHideButtonLabel: string,
    parisGamesTransportsForecastShowButtonLabel: string,
    parisGamesTransportsForecastText: string[],
    parisGamesTransportsStationStatusClosed:string,
    parisGamesTransportsStationStatusGreen:string,
    parisGamesTransportsStationStatusYellow:string,
    parisGamesTransportsStationStatusOrange:string,
    parisGamesTransportsStationStatusRed:string,
    parisGamesTransportsStationStatusNoImpact:string,
}

export const strings: LocaleStrings = new LocalizedStrings({
    en: enStrings,
    fr: frStrings
});
